import interact from "interactjs";
import { useContext, useEffect } from "react";
import { FaceContext } from "../../../../Context/face-context";
import { OptionsContext } from "../../../../Context/options-context";
import { IPosition, ISize } from "../../../../Models/IBadge";
import { instanceOfIFaceImage, instanceOfIFaceShape } from "../../../../utils";
import { EditContext } from "../../../../Context/edit-context";

const getCenter = (el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    return {
        x: rect.left + (rect.width / 2),
        y: rect.top + (rect.height / 2)
    }
}

export default function Interactable(props: {obj: IPosition & ISize, children: any}) {
    const {face, setFace} = useContext(FaceContext);
    const {options} = useContext(OptionsContext);
    const {editContext} = useContext(EditContext);

    useEffect(() => {
        if(options.locked) return;
 
        var interactables = getInteractables(props.obj);

        return () => {
            for(var interactable of interactables) interactable?.unset()
        };

    }, [props.obj, face, options.locked, editContext?.id])

    const getInteractables = (obj: IPosition & ISize) => {
        const element = document.getElementById(`${face.id}-${obj.id}`);
        if(element == null) {
            return [];
        }

        const badgeObjectId = obj.id;

        const elementBoundingRect = element.getBoundingClientRect();
        element.setAttribute('data-top', obj.yPercentage.toString());
        element.setAttribute('data-left', obj.xPercentage.toString()); 
        element.setAttribute('data-rot', (obj.rotation || 0).toString()); 
        element.setAttribute('data-trans-x', (-elementBoundingRect.width / 2).toString()); 
        element.setAttribute('data-trans-y', (-elementBoundingRect.height / 2).toString()); 

        const parent = document.getElementById(face.id)!;
        const boundingRect = parent.getBoundingClientRect();

        var interatable = interact(element!)
            .draggable({  
                ignoreFrom: '.rotate-box',
                modifiers: [
                    interact.modifiers.restrict({
                      restriction: parent,
                    }),
                    interact.modifiers.snap({
                        enabled: true,
                        targets: [
                            interact.snappers.grid({ x: 5, y: 5 }),
                        ],
                        relativePoints: [ 
                            { x: 0  , y: 0   },
                            { x: 0.5, y: 0.5 },
                            { x: 1  , y: 1   } 
                        ],
                        offset: 'self',
                        range: Infinity,
                      })
                ],
                listeners: { 
                    move (event) {
                        dragMoveListener(event, boundingRect)
                    },
                    end () {
                        dragged(element, badgeObjectId);
                    }
                }
            })
            .resizable({
                edges: { left: `.resize-left`, right: `.resize-right`, bottom: `.resize-bottom`, top: `.resize-top` },
                enabled: editContext?.id == obj.id,
                listeners: {
                    move (event) {
                        if(instanceOfIFaceImage(obj) || instanceOfIFaceShape(obj)) resizeWithAspectListener(event)
                        else resizeListener(event)
                    },
                    end () {
                        resized(element, boundingRect, badgeObjectId);
                    }
                }
            });

        const rotateElement = element.getElementsByClassName('rotate-box')[0] as HTMLElement;

        const elementCenter = getCenter(element);
        const rotateElementCenter = getCenter(rotateElement);

        const x = rotateElementCenter.x - elementCenter.x;
        const y = elementCenter.y - rotateElementCenter.y;

        rotateElement.setAttribute('data-x', x.toString());
        rotateElement.setAttribute('data-y', y.toString());

        var rotateInteractable = interact(rotateElement!)
            .draggable({  
                enabled: editContext?.id == obj.id,
                listeners: { 
                    move: e => rotateListener(e, element),
                    end () {
                        rotated(element, badgeObjectId);
                    }
                }
            })

        return [interatable, rotateInteractable];
    }

    const resetElement = (element: HTMLElement) => {
        element.removeAttribute('data-x');
        element.removeAttribute('data-y'); 
        element.removeAttribute('data-top');
        element.removeAttribute('data-left'); 
        element.removeAttribute('data-rot'); 
        element.removeAttribute('data-trans-x'); 
        element.removeAttribute('data-trans-y'); 
    }

    function resizeWithAspectListener(event: any) {
        var edgeCount = event.edges.left + event.edges.right + event.edges.top + event.edges.bottom;
        var preserveAspectRatio = edgeCount > 1;

        if(preserveAspectRatio) {
            var target = event.target

            var amountToMove = event.dx * (event.edges.left ? - 1 : 1);
            
            var width = parseFloat(target.style.width.substring(0, target.style.width.length - 2));
            var height = parseFloat(target.style.height.substring(0, target.style.height.length - 2));

            if(isNaN(width)) width = event.rect.width;
            if(isNaN(height)) height = event.rect.height;

            var aspectRatio = height / width;

            target.style.width = (width + amountToMove) + 'px'
            target.style.height = (height + (amountToMove * aspectRatio)) + 'px'
        } else {
            resizeListener(event)
        }  
    }

    function resizeListener(event: any) {
        var target = event.target
        var x = (parseFloat(target.getAttribute('data-trans-x')) || 0)
        var y = (parseFloat(target.getAttribute('data-trans-y')) || 0)
        var rot = (parseFloat(target.getAttribute('data-rot')) || 0)

        target.style.width = event.rect.width + 'px'
        target.style.height = event.rect.height + 'px'

        // translate when resizing from top or left edges
        x += event.deltaRect.left
        y += event.deltaRect.top

        target.style.transform = `translate(${x}px, ${y}px) rotate(${rot}deg)`

        target.setAttribute('data-trans-x', x);
        target.setAttribute('data-trans-y', y);
    }

    function resized(element: HTMLElement, parentBoundingRect: DOMRect, badgeObjectId: string) {
        const index = face.objects.findIndex(x => x.id == badgeObjectId);
        const object = face.objects[index] as ISize & IPosition;

        // substring where 'px' not included in number
        object.width = parseFloat(element.style.width.substring(0, element.style.width.length - 2));
        object.height = parseFloat(element.style.height.substring(0, element.style.height.length - 2));

        var center = getCenter(element);
        var left = (((center.x - parentBoundingRect.left) / parentBoundingRect.width)) * 100
        var top = (((center.y - parentBoundingRect.top) / parentBoundingRect.height)) * 100

        object.xPercentage = left;
        object.yPercentage = top;

        face.objects[index] = {...object}
        setFace({...face})

        element.style.transform = `translate(-50%, -50%) rotate(${object.rotation || 0}deg)`
        element.style.left = `${left}%`
        element.style.top = `${top}%`

        resetElement(element);
    }

    function dragMoveListener(event: any, boundingRect: DOMRect) {
        var target = event.target

        const dx = (event.dx / boundingRect.width) * 100;
        const dy = (event.dy / boundingRect.height) * 100;

        var left = parseFloat(target.getAttribute('data-left') || 0) + dx;
        var top = parseFloat(target.getAttribute('data-top') || 0) + dy;

        target.setAttribute('data-left', left)
        target.setAttribute('data-top', top)

        target.style.left = `${left}%`;
        target.style.top = `${top}%`;
    }

    const dragged = (element: HTMLElement, badgeObjectId: string) => {
        var left = parseFloat(element.getAttribute('data-left')!);
        var top = parseFloat(element.getAttribute('data-top')!);

        const index = face.objects.findIndex(x => x.id == badgeObjectId);
        const object = face.objects[index] as IPosition;

        object.xPercentage = left;
        object.yPercentage = top;

        face.objects[index] = {...object}
        setFace({...face})

        resetElement(element);
    }

    const rotateListener = (event: any, rootElement: HTMLElement) => {
        const calcAngleDegrees = (cx: number, cy: number, ex: number, ey: number)  =>{
            var dy = ey - cy;
            var dx = ex - cx;
            var theta = Math.atan2(dy, dx); // range (-PI, PI]
            theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
            if (theta < 0) theta = 360 + theta; // range [0, 360)
            return 90 - theta;
        }

        let x = parseFloat(event.target.getAttribute('data-x')!);
        let y = parseFloat(event.target.getAttribute('data-y')!);

        let newX = x + event.dx;
        let newY = y - event.dy;

        event.target.setAttribute('data-x', newX.toString());
        event.target.setAttribute('data-y', newY.toString());

        let angle = calcAngleDegrees(0, 0, newX, newY);

        // only change angle by 15 degree increments 
        angle = Math.floor(angle / 15) * 15;

        rootElement.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;
        rootElement.setAttribute('data-rot', angle.toString())
    }

    const rotated = (element: HTMLElement, badgeObjectId: string) => {
        var rotation = parseFloat(element.getAttribute('data-rot')!);

        const index = face.objects.findIndex(x => x.id == badgeObjectId);
        const object = face.objects[index] as IPosition;

        object.rotation = rotation;

        face.objects[index] = {...object}
        setFace({...face})

        resetElement(element);
    }

    return props.children;
}
