import { Card } from "@nextui-org/react";
import { useContext } from "react";
import { BadgeContext } from "../../../../Context/badge-context";
import Face from "../face/face";

export default function BadgePreviewer() {
    const {badge} = useContext(BadgeContext);

    return (
        <div id="badge-previewer-container" style={{position: 'relative', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', alignItems: 'center', gap: '20px'}}>
            <Card>
                <Face landscape={badge.landscape} lanyard={badge.lanyard} face={badge.front} scollable={true}/>
            </Card>
            <Card>
                <Face landscape={badge.landscape} lanyard={badge.lanyard} face={badge.linkSides ? badge.front : badge.back} scollable={true}/>
            </Card>
        </div>
    )
}