import { useEffect, useState } from "react"

export default function CurrentPositionWindow(props: {id: string}) {
    const [x, setX] = useState('');
    const [y, setY] = useState('');

    useEffect(() => {
        const eleement = document.getElementById(props.id);
        if (eleement == null) return;

        const interval = setInterval(() => {
            setX(eleement!.style.left.substring(0, 2)!);
            setY(eleement!.style.top.substring(0, 2)!);
        }, 100);

        return () => clearInterval(interval);
    }, [props.id])
    
    return (
        <div data-html2canvas-ignore style={{position: 'absolute', bottom: 0, right: 0, transform: 'translate(0%, 105%)', fontSize: '10px', backgroundColor: 'transparent', color: 'black', padding: '1px'}}>
            {x}% X {y}%
        </div>
    )
}