export enum ShapeType {
    Square = "Square",
    Circle = "Circle",
    TopLeftCircleQuadrant = "TopLeftCircleQuadrant",
    TopRightCircleQuadrant = "TopRightCircleQuadrant",
    BottomLeftCircleQuadrant = "BottomLeftCircleQuadrant",
    BottomRightCircleQuadrant = "BottomRightCircleQuadrant",
    QuarterPipe = "QuarterPipe",
    TopSemiCircle = "TopSemiCircle",
    BottomSemiCircle = "BottomSemiCircle",
    LeftSemiCircle = "LeftSemiCircle",
    RightSemiCircle = "RightSemiCircle",
    Bowl = "Bowl",
    Triangle = "Triangle",
    LeftTriangle = "LeftTriangle",
    RightTriangle = "RightTriangle",
    UpsideDownTriangle = "UpsideDownTriangle",
    RightRightTriangle = "RightRightTriangle",
    LeftRightTriangle = "LeftRightTriangle",
    TopLeftRightTriangle = "TopLeftRightTriangle",
    TopRightRightTriangle = "TopRightRightTriangle",
    CurvedTriangle = "CurvedTriangle",
    InvertedCurvedTriangle = "InvertedCurvedTriangle",
    DoubleCurvedTriangle = "DoubleCurvedTriangle",
}