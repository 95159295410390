import "./Checkout.css"
import { Accordion, AccordionItem, Button, CircularProgress, Input, Spacer } from "@nextui-org/react";
import { useEffect, useState } from "react"
import { isValidEmail as emailValidator, highlightValue } from "../../utils";
import { IBadgeCartItem, ICartItem } from "../../Models/ICart";
import Email from "./components/email";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faMinus, faPlus, faShoppingCart, faTrash } from "@fortawesome/free-solid-svg-icons";
import useCart from "../../Hooks/use-cart";
import { ValueChanged } from "../../Models/Types";

const USDollar =  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export default function Checkout() {
    const {cart, total, setCart, saveCart} = useCart();
    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(false);
    const [valid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setEmailValid(emailValidator(email))

        if(cart) {
            setCart({
                ...cart,
                email
            })
        }
    }, [email, cart])

    useEffect(() => {
        setIsValid(emailValid);
    }, [emailValid])

    if(cart == null) return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress />
        </div>
    )

    if(cart.items.length == 0) return (
        <div className="text-center text-lg p-4">
            Cart Empty

            <Spacer y={3} />
            <Button onClick={() => window.history.back()} startContent={<FontAwesomeIcon icon={faArrowLeft} />}>Go Back</Button>
        </div>
    )

    const updateCartItem = (index: number, cartItem: ICartItem) => {        
        cart.items[index] = cartItem;
        setCart({
            ...cart
        })
    }

    const removeCartItem = async (index: number) => {        
        cart.items.splice(index, 1);
        await saveCart({
            ...cart
        })
    }

    const checkout = async () => {
        try {
            setLoading(true);

            await saveCart({
                ...cart,
                email
            });
    
            let res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/checkout/${cart.id}`, {
                method: 'POST'
            });
    
            if(!res.ok) throw new Error();
    
            var resBody = await res.json();
    
            window.location = resBody.url;
        } catch {
        } finally {
            setLoading(false)
        }
    }

    const refreshCart = () => {
        setCart({
            ...cart!
        })
    }

    return (
        <div style={{padding: '1rem'}}>
            <div className="text-2xl">Checkout</div>

            <Spacer y={5} />

            <Accordion variant="bordered">
                <AccordionItem startContent={<FontAwesomeIcon icon={faShoppingCart} />} title={<span>Cart ({USDollar.format(total)})</span>}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px'}}>
                        {cart.items.map((cartItem: ICartItem, i: number) => (
                            <CartItem key={cartItem.id} cartItem={cartItem} onChange={x => updateCartItem(i, x)} onDelete={() => removeCartItem(i)}/>
                        ))}   
                    </div>  
                </AccordionItem>
            </Accordion>  

            <Spacer y={5} />

            <Email setEmail={setEmail} isValidEmail={emailValid} onVerified={refreshCart} />

            <Spacer y={5} />

            <div style={{display: 'flex', justifyContent: 'end', gap: '20px'}}>
                <Button 
                    color="default" 
                    onClick={() => window.history.back()}
                >
                    Cancel
                </Button>

                <Button 
                    color="warning" 
                    variant="bordered"
                    endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                    onClick={checkout} 
                    isDisabled={!valid}
                    isLoading={loading}
                >
                    Shipping & Payment
                </Button>
            </div>
        </div>
    )
}

function CartItem(props: {cartItem: ICartItem, onChange: ValueChanged<ICartItem>, onDelete: any}) {
    const instanceOfIBadge = (object: any): object is IBadgeCartItem => {
        return object != null && 'front' in object;
    }

    const setQuantity = (value: string) => {
        let quantity = parseInt(value);
        if(quantity < 1 || isNaN(quantity)) quantity = 1;
        props.onChange({...props.cartItem, quantity})
    }

    if(instanceOfIBadge(props.cartItem)) return (
        <div style={{display: 'grid', gridTemplateColumns: 'auto auto', gap: '25px', alignItems: 'center'}}>
            <img src={props.cartItem.front.url} width={100}/>
            <div>
                <span className="text-tiny text-default-500">Quantity</span>
                <div style={{display: 'grid', gridTemplateColumns: 'auto 50px auto'}}>
                    <Button
                        isIconOnly
                        variant="light"
                        onClick={() => setQuantity((props.cartItem.quantity - 1).toString())}
                    >
                        <FontAwesomeIcon icon={faMinus}/>
                    </Button>
                    <Input 
                        type="number"
                        classNames={{input: 'text-center'}}
                        variant="underlined"
                        value={props.cartItem.quantity.toString()}
                        onValueChange={setQuantity}
                        onFocus={highlightValue}
                    />
                    <Button
                        isIconOnly
                        variant="light"
                        onClick={() => setQuantity((props.cartItem.quantity + 1).toString())}
                    >
                        <FontAwesomeIcon icon={faPlus}/>
                    </Button>
                </div>

                <Button className="w-full mt-3"  color="danger" onClick={props.onDelete} isIconOnly><FontAwesomeIcon icon={faTrash}/></Button>
            </div>
        </div>
    )

    return <></>
}