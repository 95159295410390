import { Card } from "@nextui-org/react";
import { ImageType } from "../../../../Models/ImageType";
import { ValueChanged } from "../../../../Models/Types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties } from "react";

export default function AddImageMenu(props: {addImage: ValueChanged<ImageType>}) {
    const styles: CSSProperties = {
        padding: '10px',
        width: '100px',
        height: '100px',
        fontSize: '75px',
        margin: '10px',
        backgroundColor: 'skyblue',
        color: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
    };

    return (
        <div style={{display: 'flex', gap: '25px', flexWrap: 'wrap'}}>
            <Card className="bg-slate-700" isPressable onClick={() => props.addImage(ImageType.Basic)}>
                <div style={{...styles}}>
                    <FontAwesomeIcon icon={faUserTie} />
                </div>  

                <div className="text-center w-full text-lg">{ImageType.Basic}</div>          
            </Card> 

            <Card className="bg-slate-700" isPressable onClick={() => props.addImage(ImageType.Avatar)}>
                <div style={{...styles, borderRadius: '50%'}}>
                    <FontAwesomeIcon icon={faUserTie} />
                </div>

                <div className="text-center w-full text-lg">{ImageType.Avatar}</div>          
            </Card> 

            <Card className="bg-slate-700" isPressable onClick={() => props.addImage(ImageType.Rounded)}>
                <div style={{...styles, borderRadius: '20%'}}>
                    <FontAwesomeIcon icon={faUserTie} />
                </div>

                <div className="text-center w-full text-lg">{ImageType.Rounded}</div>          
            </Card>             
        </div>
    )
}