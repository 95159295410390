import { IFaceImage } from "../../../../Models/IBadge";
import ResizerArrows from "./resizer-arrows";
import CurrentPositionWindow from "./current-position-window";
import useBorderStyles from "./hooks/border-hook";
import usePositionStyles from "./hooks/position-hook";
import useSizeStyles from "./hooks/size-hook";
import usePaddingStyles from "./hooks/padding-hook";
import RotateBox from "./rotate-box";
import useBadgeObjectId from "./hooks/badge-object-id-hook";
import useSelectable from "./hooks/selectable-hook";

export default function FaceImage(props: {
    faceId: string,
    value: IFaceImage
}) {
    const {selected, selectable, onSelect} = useSelectable(props.value);
    const id = useBadgeObjectId(props.faceId, props.value.id);

    const positionStyles = usePositionStyles(props.value);
    const borderStyles = useBorderStyles(selected, props.value);
    const paddingStyles = usePaddingStyles(props.value);
    const sizeStyles = useSizeStyles(props.value);

    return (
        <div 
            id={id}
            style={{
                ...positionStyles,
                ...borderStyles,
                ...paddingStyles,
                ...sizeStyles,
                borderRadius: props.value.avatar ? '50%' : borderStyles.borderRadius,
                opacity: (props.value.opacity || 100) / 100,
                backgroundColor: props.value.backgroundColor
            }}
            onClick={onSelect}
            className={selectable ? 'badge-object-selectable' : ''}
        >
            <img className="face-image-img" src={props.value.url} style={{
                borderRadius: props.value.avatar ? '50%' :  borderStyles.borderRadius,
                width: '100%', 
                height: '100%',
                minWidth: '5px',
                userSelect: 'none'
            }} /> 

            <RotateBox visible={selected} />
            <ResizerArrows visible={selected} />
            {selected && <CurrentPositionWindow id={id}/>}
        </div>
    )
}