import { useEffect, useState } from "react";
import { IBadge, IFaceImage, IFaceText } from "../../Models/IBadge";
import { cast, getTemplates, loadSavedBadgeState, uuidv4 } from "../../utils";
import Build from "./Components/Build";
import useSWR from "swr";
import { CircularProgress } from "@nextui-org/react";

const savedBadge = loadSavedBadgeState();

// export default function BuildFromScratch() {
//     const {data} = useSWR('templates', () => getTemplates());

//     const [badge, setBadge] = useState<IBadge | null>(savedBadge);

//     useEffect(() => {
//         if(data == null || savedBadge) return;
//         setBadge(data[Math.floor(Math.random() * data.length)].badge)
//     }, [data])

//     if(badge == null) return <CircularProgress style={{margin: 'auto', marginTop: '20px'}}/>

//     return <Build badge={badge} /> 
// }

export default function BuildFromScratch() {
    return <Build badge={savedBadge || cast<IBadge>({
        landscape: false,
        lanyard: false,
        linkSides: true,
        front: {
            id: uuidv4(),
            objects: [
                cast<IFaceImage>({
                    id: uuidv4(),
                    url: '/logo.png',
                    xPercentage: 50,
                    yPercentage: 25,
                    zIndex: 0,
                    width: 150,
                    height: null,
                    borderWidth: 0
                }),
                cast<IFaceText>({
                    id: uuidv4(),
                    content: `Welcome`,
                    fontSize: 18,
                    xPercentage: 50, 
                    yPercentage: 50,
                    bold: true,
                    zIndex: 1,
                    width: null,
                    height: null,
                    borderWidth: 0,
                    textAlign: 'center',
                }),
                cast<IFaceText>({
                    id: uuidv4(),
                    content: `Click the eraser below to clear me\nand start creating your own badge`,
                    fontSize: 14,
                    xPercentage: 50, 
                    yPercentage: 70,
                    bold: false,
                    zIndex: 1,
                    width: null,
                    height: null,
                    borderWidth: 0,
                    textAlign: 'center',
                    italic: true
                })
            ]
        },
        back: {
            id: uuidv4(),
            objects: []
        }
    })} /> 
}


