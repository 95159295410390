import { useContext, useState, useEffect } from "react";
import { BadgeContext } from "../Context/badge-context";
import { IFace } from "../Models/IBadge";
import { instanceOfIFaceText, instanceOfIFaceImage, instanceOfIFaceShape } from "../utils";

export default function useBadgeColors() {
    const {badge} = useContext(BadgeContext);
    const [colors, setColors] = useState<string[]>([]);

    useEffect(() => {
        let colors = new Set<string>();

        var collectColors = (face: IFace) => {
            if(face.color) colors.add(face.color);

            for(var object of face.objects) {
                if (instanceOfIFaceText(object) && object.color) {
                    colors.add(object.color);
                }

                if((instanceOfIFaceText(object) || instanceOfIFaceImage(object) || instanceOfIFaceShape(object))) {
                    if(object.backgroundColor) colors.add(object.backgroundColor);
                    if(object.borderColor) colors.add(object.borderColor);
                }
            }
        }

        collectColors(badge.front);
        collectColors(badge.back);

        setColors([...colors].sort())
    }, [badge])

    return colors;
}
