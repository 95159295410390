import { faFill, faPlus, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popover, PopoverContent, PopoverTrigger, Tooltip } from "@nextui-org/react";
import { useState } from "react";
import useBadgeColors from "../../../../Hooks/use-badge-colors";

export default function ColorPicker(props: {
    value?: string | null, 
    onValueChange: any, color?: any,
    icon?: IconDefinition,
    tooltip?: string,
    size?: "sm" | "md" | "lg", 
}) {
    const [open, setOpen] = useState(false);
    const colors = useBadgeColors();
 
    if(!open) {
        return (
            <Tooltip content={props.tooltip}>
                <Button isIconOnly color={props.color || 'default'} size={props.size} onClick={() => setOpen(true)}>
                    <FontAwesomeIcon icon={props.icon || faFill} style={{color: props.value || ''}} size="lg"/>    
                </Button>
            </Tooltip>
        )
    }

    return (
        <Popover isOpen={open} onOpenChange={setOpen}>
            <PopoverTrigger>
                <Button isIconOnly color={props.color || 'default'} size={props.size}>
                    <FontAwesomeIcon icon={props.icon || faFill} style={{color: props.value || ''}} size="lg"/>    
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <div style={{display: 'flex', gap: '15px'}}>
                    {colors.map(color => (
                        <div key={color}>
                            <Button key={color} isIconOnly style={{backgroundColor: color}} onClick={() => props.onValueChange(color)}></Button>
                        </div>
                    ))}

                    <div>
                        <Button isIconOnly color={'default'} size={props.size}>
                            <div style={{position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center"}}>
                                <FontAwesomeIcon icon={faPlus} style={{color: props.value || ''}} size="lg"/>
                                <input className="color-picker" type="color" value={props.value || ''} onChange={e => props.onValueChange(e.target.value)} style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}} />
                            </div>
                        </Button>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}