import { useContext, useEffect, useState } from "react";
import { EditContext } from "../../../../Context/edit-context";
import FaceImageMenu from "./face-image";
import FaceTextMenu from "./face-text";
import { Button, Divider, Spacer, Tab, Tabs } from "@nextui-org/react";
import { instanceOfIFaceText, instanceOfIFaceImage, instanceOfIFace, instanceOfIFaceShape } from "../../../../utils";
import { faCheck, faCopy, faFont, faMountainSun, faPalette, faPallet, faPlus, faShapes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FaceMenu from "./face";
import FaceShapeMenu from "./face-shape";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import { IFace, IFaceImage, IFaceShape, IFaceText } from "../../../../Models/IBadge";
import { FaceContext } from "../../../../Context/face-context";
import AddShapesMenu from "./add-shapes";
import Theme from "./theme";
import AddImageMenu from "./add-images";
import AddTextMenu from "./add-text";

function AddCopyAndDelete(props: {
    color: any, 
    copy: boolean,
    onAdd?: any
}) {
    const {editContext, setEditContext, onDelete, onCopy} = useContext(EditContext);

    return (
        <div style={{display: 'flex', gap: '15px'}}>
            {props.onAdd != null && (
                <div>
                    <p className="text-tiny text-default-500 mb-1">Add</p>
                    <Button color="success" onClick={props.onAdd} isIconOnly><FontAwesomeIcon icon={faPlus} /></Button>
                </div>
            )}
            <div className="d-small">
                <p className="text-tiny text-default-500 mb-1">Apply</p>
                <Button color="primary" isIconOnly onClick={() => setEditContext(null)}><FontAwesomeIcon icon={faCheck}/></Button>
            </div>
            {props.copy && editContext != null && (
                <div>
                    <p className="text-tiny text-default-500 mb-1">Copy</p>
                    <Button color="default" isIconOnly onClick={onCopy}><FontAwesomeIcon icon={faCopy}/></Button>
                </div>
            )}

            {editContext != null && (
                <div>
                    <p className="text-tiny text-default-500 mb-1">Delete</p>
                    <Button color="danger" isIconOnly onClick={onDelete}><FontAwesomeIcon icon={faTrash}/></Button>
                </div>
            )}
        </div>
    )
}

export function EditContextSmallMenu() {
    const {editContext} = useContext(EditContext);

    if(editContext == null) return <></>

    return (
        <div className='p-4 bg-foreground-50 border rounded-xl border-default-300'>
            <SmallMenu />
        </div>
    )
}

function SmallMenu() {
    const {editContext, setEditContext, onDelete, onCopy} = useContext(EditContext);
    const formSize = "md"
    
    if(instanceOfIFaceImage(editContext)) return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p className="text-lg text-default-1000">Edit Image</p>
                <AddCopyAndDelete color="primary" copy={true} />
            </div>  
            <Spacer y={2} />  
            <Divider />
            <Spacer y={2} />        
            <FaceImageMenu size={formSize} color="primary" value={editContext} onValueChanged={setEditContext} />
        </div>
    )

    if(instanceOfIFaceText(editContext)) return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p className="text-lg text-default-1000">Edit Text</p>
                <AddCopyAndDelete color="secondary" copy={true} />
            </div>
            <Spacer y={2} />  
            <Divider />
            <Spacer y={2} /> 
            <FaceTextMenu size={formSize} color="secondary" value={editContext} onValueChanged={setEditContext} />
        </div>
    )

    if(instanceOfIFaceShape(editContext)) return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p className="text-lg text-default-1000">Edit Shape</p>
                <AddCopyAndDelete color="warning" copy={true} />
            </div>
            <Spacer y={2} />
            <Divider />
            <Spacer y={2} /> 
            <FaceShapeMenu size={formSize} color="warning" value={editContext} onValueChanged={setEditContext} />
        </div>
    )

    if(instanceOfIFace(editContext)) return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p className="text-lg text-default-1000">Edit Background</p>
                <AddCopyAndDelete color="default" copy={false} />
            </div>
            <Spacer y={2} />
            <Divider />
            <Spacer y={2} /> 
            <FaceMenu size={formSize} color="default" value={editContext} onValueChanged={setEditContext} />
        </div>
    )

    return <></>
}


export function EditContextLargeMenu() {
    const {addText, addImage, addShape} = useContext(FaceContext); 
    const {editContext, setEditContext} = useContext(EditContext);

    const [selected, setSelected] = useState("background");
    const formSize = "md"

    useEffect(() => {
        if (instanceOfIFaceText(editContext)) setSelected('text');
        else if(instanceOfIFaceImage(editContext)) setSelected('image')
        else if(instanceOfIFaceShape(editContext)) setSelected('shape')
        else if(instanceOfIFace(editContext)) setSelected("background")
    }, [editContext])

    const openAddTextMenu = () => {
        setEditContext(null);
        setSelected('text')
    }

    const openAddImageMenu = () => {
        setEditContext(null);
        setSelected('image')
    }

    const openAddShapeMenu = () => {
        setEditContext(null);
        setSelected('shape')
    }

    

    return (
        <div className='bg-foreground-50 border rounded-xl border-default-300 h-full' onClick={e => e.stopPropagation()}>
            <Tabs 
                isVertical 
                selectedKey={selected} 
                onSelectionChange={k => setSelected(k as string)}
                classNames={{
                    tab: 'p-5 text-xl justify-start',
                    panel: 'p-4 w-full',
                    tabList: 'h-full',
                    base: 'h-full',
                    wrapper: 'h-full'
                }}
            >
                <Tab key="theme" title={
                    <div className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faPalette} />
                        <div>Colors</div>                        
                    </div>
                }>
                    <div>
                        <Theme />
                    </div>
                </Tab>
                <Tab key="background" title={
                    <div className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faMountainSun} />
                        <div>Background</div>                        
                    </div>
                }>
                    {!instanceOfIFace(editContext)
                    ?
                        <div>
                            Select background to edit
                        </div>
                    :
                        <div>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <p className="text-lg text-default-1000">Edit Background</p>
                                <AddCopyAndDelete color="default" copy={false} />
                            </div>
                            <Spacer y={2} />
                            <Divider />
                            <Spacer y={2} /> 
                            <FaceMenu size={formSize} color="default" value={editContext as IFace} onValueChanged={setEditContext} />
                        </div>
                    }
                </Tab>
                <Tab key="text" title={
                    <div className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faFont} />
                        <div>Text</div>
                    </div>
                }>
                    <div>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p className="text-lg text-default-1000">Text</p>
                            <AddCopyAndDelete color="secondary" copy={true} onAdd={openAddTextMenu} />
                        </div>  
                        <Spacer y={2} />
                        <Divider />
                        <Spacer y={2} />

                        {instanceOfIFaceText(editContext)
                        ? <FaceTextMenu size={formSize} color="secondary" value={editContext as IFaceText} onValueChanged={setEditContext} />
                        : <AddTextMenu addText={addText}/>
                        }
                    </div>
                </Tab>
                <Tab key="image" title={
                    <div className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faImage} />
                        <div>Images</div>
                    </div>
                }>     
                    <div>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p className="text-lg text-default-1000">Images</p>
                            <AddCopyAndDelete color="primary" copy={true} onAdd={openAddImageMenu} />
                        </div>  
                        <Spacer y={2} />  
                        <Divider />
                        <Spacer y={2} />
                        
                        {instanceOfIFaceImage(editContext)
                        ? <FaceImageMenu size={formSize} color="primary" value={editContext as IFaceImage} onValueChanged={setEditContext} />
                        : <AddImageMenu addImage={addImage}/>
                        }
                    </div>
                </Tab>
                <Tab key="shape" title={
                    <div className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faShapes} />
                        <div>Shapes</div>
                    </div>
                }> 
                    <div>
                        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p className="text-lg text-default-1000">Shapes</p>
                            <AddCopyAndDelete color="warning" copy={true} onAdd={openAddShapeMenu}/>
                        </div>
                        <Spacer y={2} />
                        <Divider />
                        <Spacer y={2} /> 
                        {instanceOfIFaceShape(editContext)
                        ? <FaceShapeMenu size={formSize} color="warning" value={editContext as IFaceShape} onValueChanged={setEditContext} />
                        : <AddShapesMenu addShape={addShape}/>
                        }
                    </div>                    
                </Tab>
            </Tabs>
        </div>
    )
}


