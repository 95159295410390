import { Card, CardFooter } from "@nextui-org/react";
import { CSSProperties, useContext, useEffect, useState } from "react"
import { getFaceImages } from "../../../../utils";
import { BadgeContext } from "../../../../Context/badge-context";
import { EditContext } from "../../../../Context/edit-context";


export default function Preview() {
    const [imgSrc, setImgSrc] = useState('');
    const {badge} = useContext(BadgeContext);
    const {editContext} = useContext(EditContext);
    const [width, setWidth] = useState(19);
    const [timeout, setStateTimeout] = useState<any>(null);

    const update = () => {
        getFaceImages("badge-previewer-container")
            .then(faces => URL.createObjectURL(faces[0]))
            .then(setImgSrc)
            .catch(() => {});

        setWidth(badge.lanyard ? 22 : 19)
    }

    useEffect(() => {
        if(timeout != null) clearTimeout(timeout);
        setStateTimeout(setTimeout(update, 1000));
    }, [badge, editContext])

    const previewContainerStyles: CSSProperties = badge.landscape
    ? {
        transform: 'translate(50px, -20px)  scale(1.25)',
        width: '400px', 
    }
    : {
        transform: 'translate(0px, -90px) scale(2)',
        width: '300px', 
        backgroundColor: 'white'

    }

    const imageStyles: CSSProperties = badge.landscape 
    ? {
        left: '38.18%',
        top: '51%',
        width: '26.95%',
        transform: 'translate(-50%, -50%) rotate(-1.6deg)',
        borderRadius: '5px'
    }
    : {
        left: badge.lanyard ? '48.6%' : '48.8%',
        top: badge.lanyard ? '61.1%' : '62%',
        width: `${width}%`,
        borderRadius: '5px',
        padding: badge.lanyard ? '' : '2px',
        transform: 'translate(-50%, -50%) rotate(1.3deg)',
        backgroundSize: `${width}% ${width * 1.1}%`
    }


    return (
        <div style={{display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
            <div>
                <Card isFooterBlurred>                   
                    <div id="preview-container" style={{
                            ...previewContainerStyles,
                            position: 'relative',
                            zIndex: 2
                        }}
                    >
                        <img src={imgSrc}
                            style={{
                                ...imageStyles,
                                zIndex: -1,
                                position: 'absolute',
                                border: 'solid .5px whitesmoke',
                                backgroundColor: 'whitesmoke',
                            }} />
                        <img src={`${process.env.REACT_APP_ASSETS_PATH}/images/preview-background-transparent-${badge.landscape ? 'landscape' : 'portrait'}.png`} />
                    </div>

                    <CardFooter className="justify-center before:bg-white/10 border-white/20 border-1 overflow-hidden py-2 absolute before:rounded-xl rounded-large bottom-1 shadow-small m-1 w-[calc(100%_-_8px)] z-10">
                        <p className="text-md text-white/80">Preview</p>
                    </CardFooter>
                </Card>
            </div>
        </div>
    )
}