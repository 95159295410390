import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spacer, Accordion, AccordionItem, Chip, Link } from "@nextui-org/react";

export default function FAQ() {
    return (
        <div >
            <div className="text-4xl text-center lg:text-left">
                Frequenty Asked Questions
            </div>

            <Spacer y={10} />

            <div style={{flexGrow: '1'}}>
                <Accordion variant="shadow">
                    <AccordionItem title="Why choose Build A Badge?">
                        <div style={{display: 'flex', gap: '5px', flexWrap: 'wrap'}}>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> High Quality</Chip>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Badge Templates</Chip>
                        </div>
                        <Spacer y={5} />
                        <div className="text-lg">
                            All of our badges are inspected thouroughly for a guaranteed great looking badge. 
                        
                            <Spacer y={2} />

                            We offer a wide range of badge templates and our badge editor allows you to build your own badge from scratch. 
                        </div>
                    </AccordionItem>
                    <AccordionItem title="What type of badges are available?">
                        <div style={{display: 'flex', gap: '5px', flexWrap: 'wrap'}}>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Portrait</Chip>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Landscape</Chip>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Lanyard</Chip>
                        </div>
                        <Spacer y={5} />
                        <div className="text-lg">
                            Badges can be printed in portrait or landscape mode. 
                        
                            <Spacer y={2} />

                            Portrait badges can have a hole punched at the top for lanyard support. 

                            <Spacer y={2} />

                            <span className="text-default-500">Landscape badges do not have an option for punch holes.</span> 
                        </div>
                    </AccordionItem>
                    <AccordionItem title="How big is a badge?">
                        <div className="text-lg">
                            Badges are the same size as a credit card 
                            <Spacer y={5} />
                            <div>3.375 inches wide by 2.125 inches high</div>
                        </div>
                    </AccordionItem>
                    <AccordionItem title="How much do badges cost?">
                        <div style={{display: 'flex', gap: '5px', flexWrap: 'wrap'}}>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Affordable</Chip>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Free Shipping</Chip>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Discounts</Chip>
                        </div>
                        <Spacer y={5} />
                        <div className="text-lg">
                            You can use our <Link href="pricing">pricing calculator</Link> for more details
                        </div>
                    </AccordionItem>
                    <AccordionItem title="What type of printer is used?">
                        <div className="text-lg">
                            Fargo HDP 6600 Printer
                        </div>
                    </AccordionItem>
                    <AccordionItem title="How are my badges stored?">
                        <div style={{display: 'flex', gap: '5px', flexWrap: 'wrap'}}>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Secure</Chip> 
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Private</Chip> 
                        </div>
                        <Spacer y={5} />
                        <div className="text-lg">
                            Badges you create are stored in a secure, privately accessible data store. 
                            <Spacer y={2} />
                            Your badges and images you upload are not shared or visible to other users. 
                        </div>
                    </AccordionItem>
                    <AccordionItem title="Do badges have a magnetic strip?">
                        <div style={{display: 'flex', gap: '5px', flexWrap: 'wrap'}}>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Barcode</Chip>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> QR Code</Chip>
                            <Chip color="danger"><FontAwesomeIcon icon={faX} className="mr-1"/>Magnetic</Chip>
                        </div>
                        <Spacer y={5} />
                        <div className="text-lg">
                            No, however, our badges allow you to add a barode or qr code.
                        </div>
                    </AccordionItem>
                    <AccordionItem title="What are the use cases?">
                        <div style={{display: 'flex', gap: '5px', flexWrap: 'wrap'}}>
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Employee</Chip> 
                            <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Entertainment</Chip> 
                            <Chip color="danger"><FontAwesomeIcon icon={faX} className="mr-1"/>Official</Chip>
                        </div>
                        <Spacer y={5} />
                        <div className="text-lg">
                            Build A Badge is tailored for professional business employee IDs but can also be used for entertainment and parody reasons.   
                            <Spacer y={2} />
                            Build A Badge should not be used for "Fake IDs" that resemble official IDs.
                        </div>
                    </AccordionItem>
                    
                </Accordion>
            </div>
        </div>
    )
}