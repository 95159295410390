import { useState, useContext, useEffect } from "react";
import { EditContext } from "../../../../../Context/edit-context";
import { OptionsContext } from "../../../../../Context/options-context";
import { IBadgeObject } from "../../../../../Models/IBadge";

export default function useSelectable(badgeObject: IBadgeObject) {
    const [selected, setSelected] = useState(false);
    const {editContext, setEditContext} = useContext(EditContext);
    const {options} = useContext(OptionsContext);

    useEffect(() => {
        setSelected(editContext != null && editContext?.id === badgeObject.id)
    }, [editContext])

    return {
        selected,
        selectable: !selected && !options.locked,
        onSelect: (e:  React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if(options.locked) return;

            e.stopPropagation();
            setEditContext(badgeObject);
        }
    }
}