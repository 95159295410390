export const basicFontFamilies = [
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Georgia',
    'Garamond',
    'Courier New',
    'Brush Script MT',
    'Monoton',
    'Funnel Display',
    'Alumni Sans Pinstripe',
    'Pacifico',
    'Meow Script',
    'Yellowtail',
    'Barriecito',
    'Rubik Moonrocks',
    'Butcherman',
    'Moirai One',
    'Lavishly Yours',
    'Pattaya',
    'Obitron',
    'Turret Road'
]

export const popularFontFamilies = [
    'Arial',
    'Times New Roman',
    'Teko',
    'Roboto',
    'Goldman',
]

export const fontFamilies = [
    ...popularFontFamilies,
    ...basicFontFamilies
].sort()
