import { Button, Input } from "@nextui-org/react";
import { ISize } from "../../../../Models/IBadge";
import { highlightValue } from "../../../../utils";
import { ValueChanged } from "../../../../Models/Types";
import { useContext } from "react";
import { FaceContext } from "../../../../Context/face-context";


export default function SizeMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: ISize,
    onValueChanged: ValueChanged<ISize>
}) {
    const {face} = useContext(FaceContext);

    const setWidth = (width: number) => props.onValueChanged({...props.value, width});
    const setHeight = (height: number) => props.onValueChanged({...props.value, height});

    const getFaceRect = () => document.getElementById(face.id)!.getBoundingClientRect();

    const setFullWidth = () => {
        var rect = getFaceRect();
        setWidth(rect.width)
    }

    const setFullHeight = () => {
        var rect = getFaceRect();
        setHeight(rect.height)
    }

    return (
        <div style={{display: 'flex', gap: '10px'}}>
            <div>
                <p className="text-tiny text-default-500 mb-1">Width</p>
                <Input 
                    color={props.color}
                    size={props.size}
                    variant="bordered"
                    type="number" 
                    value={props.value.width?.toString()} 
                    onValueChange={v => setWidth(parseInt(v))}
                    endContent={<div>px</div>}
                    onFocus={highlightValue}
                />
                <Button
                    color={props.color}
                    size="sm"
                    onClick={setFullWidth}
                    className="w-full mt-1"
                >
                    Full
                </Button>
            </div>

            <div>
                <p className="text-tiny text-default-500 mb-1">Height</p>
                <Input 
                    color={props.color}
                    size={props.size}
                    variant="bordered"
                    type="number" 
                    value={props.value.height?.toString()} 
                    onValueChange={v => setHeight(parseInt(v))}
                    endContent={<div>px</div>}
                    onFocus={highlightValue}
                />
                <Button
                    color={props.color}
                    size="sm"
                    onClick={setFullHeight}
                    className="w-full mt-1"
                >
                    Full
                </Button>
            </div>
        </div>
    )
}