import { CSSProperties, useEffect, useState } from "react";
import { IBorder } from "../../../../../Models/IBadge";

export default function useBorderStyles(selected: boolean, border: IBorder) {
    
    const calculate = (): CSSProperties => {
        return {
            borderWidth: `${border.borderWidth || (selected ? 1 : 0)}px`,
            borderColor: `${border.borderColor || 'black'}`,
            borderStyle: selected ? 'dashed' : 'solid',
            borderRadius: border.borderRadius ? `${border.borderRadius}px` : 'unset'
        }
    }

    const [styles, setStyles] = useState<CSSProperties>(calculate());

    useEffect(() => {
        setStyles(calculate())
    }, [selected, border]);

    return styles;
}