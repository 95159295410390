import { faFill } from "@fortawesome/free-solid-svg-icons";
import { IBowlShape, IFace, IFaceShape } from "../../../../Models/IBadge";
import ColorPicker from "./color-picker";
import { Accordion, AccordionItem, Input } from "@nextui-org/react";
import BorderMenu from "./border";
import PositionMenu from "./position";
import RotationMenu from "./rotation";
import SizeMenu from "./size";
import LayerMenu from "./layer";
import { highlightValue } from "../../../../utils";
import { ShapeType } from "../../../../Models/ShapeType";
import { ValueChanged } from "../../../../Models/Types";

export default function FaceShapeMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IFaceShape,
    onValueChanged: ValueChanged<IFaceShape>
}) {

    const setBackgroundColor = (backgroundColor: string) => props.onValueChanged({...props.value, backgroundColor})
    const setOpacity = (opacity: number) => {
        if(opacity < 1 || isNaN(opacity)) opacity = 1;
        else if (opacity > 100) opacity = 100;

        props.onValueChanged({
            ...props.value,
            opacity
        })
    }
    
    return (
        <div style={{display: 'grid', gap: '25px'}}>
            <div style={{display: 'flex', gap: '15px'}}>
                <ColorPicker tooltip="Fill Color" size={props.size} color={props.color} icon={faFill} value={props.value.backgroundColor} onValueChange={setBackgroundColor} />
                <LayerMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceShape)} />
            </div>

            <div>
                <p className="text-tiny text-default-500 mb-2">Opacity</p>
                <Input 
                    size={props.size}
                    color={props.color}
                    variant="bordered"
                    value={(props.value.opacity || 100).toString()} 
                    onValueChange={v => setOpacity(parseFloat(v))} 
                    onFocus={highlightValue}
                />
            </div> 

            <ShapeSpecificMenu value={props.value} onValueChanged={props.onValueChanged}/>
            
            <Accordion selectionMode="multiple">
                <AccordionItem title="Border">
                    <BorderMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceShape)} />
                </AccordionItem>
                <AccordionItem title="Position">
                    <PositionMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceShape)} />
                </AccordionItem>
                <AccordionItem title="Rotation">
                    <RotationMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceShape)} />
                </AccordionItem>
                <AccordionItem title="Size">
                    <SizeMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceShape)} />
                </AccordionItem>
            </Accordion>
        </div>
    )
}

function ShapeSpecificMenu(props: {
    value: IFaceShape, 
    onValueChanged: ValueChanged<IFaceShape>
}) {
    if(props.value.shape == ShapeType.Bowl) return <BowlMenu value={props.value} onValueChanged={props.onValueChanged}/>

    return <></>
}

function BowlMenu(props: {
    value: IBowlShape,
    onValueChanged: ValueChanged<IBowlShape>
}) {
    const setRadiusOffset = (radiusOffset: number) => props.onValueChanged({...props.value, radiusOffset})

    return (
        <div>
            <p className="text-tiny text-default-500 mb-2">Radius</p>
            <Input 
                type="number"
                variant="bordered"
                value={props.value.radiusOffset?.toString()}
                onValueChange={v => setRadiusOffset(parseInt(v))}
                onFocus={highlightValue}
            />
        </div>
    )
}