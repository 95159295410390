import useSWR from "swr"
import { ICart } from "../Models/ICart";
import { cast, createJsonPatchDocument, getCookie } from "../utils";
import { useEffect, useState } from "react";
import { appInsights } from "../App-Insights";

export const DefaultBadgePrice = 6.99;

const getOrCreateCart = async (): Promise<ICart> => {
    var res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/cart`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include"
    });

    var cartJson = await res.json();

    return cartJson;
}

const updateCart = async (oldCart: ICart, currentCart: ICart): Promise<ICart> => {    
    const patch = createJsonPatchDocument(oldCart, currentCart);

    if(patch.length > 0) {

        let res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/cart`, {
            method: 'POST',
            body: JSON.stringify(patch),
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: "include"
        });

        if(!res.ok) throw new Error();

        return await res.json();
    }

    return currentCart;
}

export default function useCart() {
    const { data, error, isLoading } = useSWR('cart', getOrCreateCart)
    const [previousCart, setPreviousCart] = useState(data);
    const [cart, setCart] = useState(data);
    const [addPrice, setAddPrice] = useState(DefaultBadgePrice);
    const [total, setTotal] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [emailVerified, setEmailVerified] = useState(false);

    const firstBadgeDiscount = .5;
    const multiDiscount = .25;

    const copyCart = (cart: ICart): ICart => {
        return cast<ICart>({
            ...cart,
            items: cart.items.map(x => {
                return {
                    ...x
                }
            })
        })
    }

    useEffect(() => {
        if(data) setPreviousCart(copyCart(data));
        setCart(data)
    }, [data])

    useEffect(() => {
        if(!error) return;
        appInsights.trackException(error)
    }, [error])

    useEffect(() => {
        let quantity = 0;

        if (cart != null) {
            for(var item of cart.items) quantity += item.quantity;
            setEmailVerified(cart.email != null && cart.email == getCookie('email'))
        }

        setQuantity(quantity);
    }, [cart])

    useEffect(() => {
        let total = DefaultBadgePrice * quantity;

        if(emailVerified) total -= DefaultBadgePrice * (1 - firstBadgeDiscount);
        if(quantity > 1) total *= (1 - multiDiscount);

        setTotal(total)

        if(quantity == 0) {
            setAddPrice(DefaultBadgePrice * (1 - firstBadgeDiscount))
        } else {

            let futureQuantity = quantity + 1;
            let futureTotal = DefaultBadgePrice * futureQuantity;
            futureTotal -= (DefaultBadgePrice * (1 - firstBadgeDiscount))
            futureTotal *= (1 - multiDiscount);

            setAddPrice(futureTotal / futureQuantity);

        }
    }, [quantity, emailVerified])
   
    return {
        cart,
        setCart,
        cartError: error,
        cartLoading: isLoading,
        saveCart: async (newCart: ICart) => {
            const updatedCart = await updateCart(previousCart!, newCart);
            setCart(updatedCart);
            setPreviousCart(copyCart(updatedCart));
        },
        addPrice,
        total
    }
}

