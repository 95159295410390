import "./Pricing.css"
import { Chip, Divider, Input, Spacer, Tooltip, Image, Button, Link } from "@nextui-org/react";
import { DefaultBadgePrice } from "../../Hooks/use-cart";
import { faCheck, faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { highlightValue } from "../../utils";

export default function Pricing() {
    return (
        <div className="p-4 lg:p-10">
            <div className="bg-gray-900 p-4 lg:p-10" style={{display: 'flex', justifyContent: 'center', gap: '150px'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="text-3xl font-semibold">
                        Build A Badge pricing 
                    </div>

                    <Spacer y={5} />

                    <div style={{display: 'flex', gap: '5px', flexWrap: 'wrap', justifyContent: 'center'}}>
                        <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Affordable</Chip>
                        <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Free Shipping</Chip>
                        <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Discounts</Chip>
                    </div>

                    <Spacer y={5} />

                    <div className="text-center">
                        Use our pricing calculator below to simulate how much an order will cost
                    </div>
                </div>

                <div className="d-big">
                    <Image 
                        src={`${process.env.REACT_APP_ASSETS_PATH}/images/calculator.jpg`}
                        className="w-[500px]"
                    />
                </div>
            </div>

            <Spacer y={20} />

            <Calculator />

            <Spacer y={20} />

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button variant="bordered" color="primary" as={Link} href="contact">Contact us for more information</Button> 
            </div>
        </div>
    )
}

const USDollar =  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

function Calculator() {
    const [numBadges, setNumBadges] = useState(1);
    const [subTotal, setSubTotal] = useState(DefaultBadgePrice);
    const [total, setTotal] = useState(DefaultBadgePrice);

    const setBadges = (v: string) => {
        let val = parseInt(v);

        if(val < 1 || isNaN(val)) val = 1;
        else if (val >  100) val = 100;

        setNumBadges(val);
    }

    useEffect(() => {
        let total = numBadges * DefaultBadgePrice;
        setSubTotal(total);

        // first badge discount
        total -= (DefaultBadgePrice * .5)

        if(numBadges > 1) total *= .75;

        setTotal(total);
    }, [numBadges])

    return (
        <div id="pricing">
            <div>
                <div className="text-lg">
                    Options
                </div>
                
                <Spacer y={3} />
                <Divider />
                <Spacer y={3} />

                <Input
                    label="# Badges"
                    description="How many badges you plan on ordering"
                    type="number"
                    value={numBadges.toString()}
                    onValueChange={setBadges}
                    onFocus={highlightValue}
                    className="mb-4"
                />

                <div className="text-center p-2 border border-success rounded">
                    Get a <span>discount</span> by ordering more than one badge
                </div>
            </div>

            <div>
                <div className="text-lg">
                    Summary
                </div>
                
                <Spacer y={3} />
                <Divider />
                <Spacer y={3} />

                <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                    <div className="text-default-500">Subtotal</div>
                    <div className="font-bold">{USDollar.format(subTotal)}</div>
                </div>

                <Spacer y={3} />
                <Divider />
                <Spacer y={3} />

                <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                    <div className="text-default-500">
                        <FontAwesomeIcon icon={faTags} className="mr-1"/>
                        <span className="mr-1">First Badge Discount</span>
                        <Tooltip content="Discount only applies on first badge when you verify your email during checkout">
                            <FontAwesomeIcon icon={faQuestionCircle} />
                        </Tooltip>
                    </div>
                    <div className="font-bold">-{USDollar.format(DefaultBadgePrice * .5)}</div>
                </div>
            
                {numBadges > 1 && (
                    <>
                    <Spacer y={3} />
                    <Divider />
                    <Spacer y={3} />

                    <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                        <div className="text-default-500">
                            <FontAwesomeIcon icon={faTags} className="mr-1"/>
                            <span>Bulk Order Discount</span>
                        </div>
                        <div className="font-bold">25% Off</div>
                    </div>
                    </>
                )}

                <Spacer y={3} />
                <Divider />
                <Spacer y={3} />

                <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                    <div className="text-default-500">Shipping</div>
                    <div className="font-bold">FREE</div>
                </div>

                <Spacer y={3} />
                <Divider />
                <Spacer y={3} />

                <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                    <div className="text-default-500">Tax</div>
                    <div className="font-bold">N/A</div>
                </div>

                <Spacer y={3} />
                <Divider />
                <Spacer y={3} />

                <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                    <div className="text-2xl">Total</div>
                    <div className="font-bold text-2xl">{USDollar.format(total)}</div>
                </div>
            </div>
        </div>
    )
}