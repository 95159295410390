import { Card, Spacer } from "@nextui-org/react";
import { ValueChanged } from "../../../../Models/Types";
import { fontFamilies, popularFontFamilies } from "../../../../Data";

export default function AddTextMenu(props: {addText: ValueChanged<string>}) {

    const fontFamilyList = (name: string, families: string[]) => {
        return (
            <div>
                <div className="text-2xl">{name}</div>

                {families.map(fontFamily => (
                    <Card key={fontFamily} className="my-5 w-full" isPressable  onClick={() => props.addText(fontFamily)}>
                        <div className="text-2xl p-2 bg-slate-700 w-full" style={{fontFamily}}>{fontFamily}</div>
                    </Card>
                ))}
            </div>
        )
    }

    return (
        <div style={{maxHeight: '600px', overflowY: 'scroll'}}>
            {fontFamilyList("Popular", popularFontFamilies)}

            <Spacer y={5} />

            {fontFamilyList("All", fontFamilies)}

        </div>
    )
}