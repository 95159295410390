import { faEnvelope, faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, CardBody, Image, Input, Link, Spacer, Textarea } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { isValidEmail } from "../utils";
import FAQ from "../Components/faq";

export default function Contact() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [received, setReceived] = useState(false);

    const MaxMessageLength = 500;

    useEffect(() => {
        setValid(
            isValidEmail(email)
            && message.length > 3 && message.length <= MaxMessageLength
        )
    }, [email, message])

    const submit = () => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email, message})
        })
        .then(res => {
            if(res.ok) {
                setReceived(res.ok)
            }
        })
        .finally(() => setLoading(false))
    }

    if(received) return (
        <div style={{margin: 'auto', textAlign: 'center',  padding: '20px'}}>
            <div>
                <div className="text-2xl">Thank your for your feedback!</div>
                <div>We will be in contact soon.</div>
                <Spacer y={5} />
                <Button as={Link} href="/" startContent={<FontAwesomeIcon icon={faHome}/>}>Go Back Home</Button>
            </div> 
        </div>
    )

    return (
        <div className="p-4 lg:p-10">
            <div className="bg-cyan-950 p-4 lg:p-10" style={{display: 'flex', justifyContent: 'center', gap: '150px'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <div className="text-3xl font-semibold">
                        Support
                    </div>

                    <Spacer y={5} />

                    <div className="text-center">
                        Describe your problem in the form below or give us a call
                    </div>
                </div>

                <div className="d-big">
                <Image 
                    src={`${process.env.REACT_APP_ASSETS_PATH}/images/support.jpg`}
                    className="w-[500px]"
                />
                </div>
            </div>

            <Spacer y={20} />

            <div className="lg:w-[1000px] lg:grid flex" style={{gridTemplateColumns: '1fr 1fr', gap: '50px', flexWrap: 'wrap', margin: 'auto'}}>
                <div style={{display: 'grid', gridTemplateColumns: 'auto', gap: '15px', alignContent: 'start'}} className="lg:w-[500px] w-full" >
                    <Input
                        type="email"
                        label="Email"
                        description="The email you want us to respond to"
                        startContent={<FontAwesomeIcon icon={faEnvelope}/>}
                        value={email}
                        onValueChange={setEmail}
                    />

                    <Textarea
                        value={message}
                        onValueChange={setMessage}
                        placeholder="Tell us how we can help"
                        description={`${message.length} / ${MaxMessageLength}`}
                    />

                    <Button color="primary" isDisabled={!valid} isLoading={loading} onClick={submit}>Submit</Button>
                </div>

                <div>
                    <Card>
                        <Image 
                            src={`${process.env.REACT_APP_ASSETS_PATH}/images/cell-phone.jpg`}
                        />

                        <CardBody>
                            <div className="text-2xl text-center">Call Us</div>

                            <div className="text-md text-center mt-2 text-default-500">9:00 AM–7:00 PM Central Standard Time</div>

                            <div className="text-center mt-5">
                                <Button
                                    as={Link}
                                    href="tel:8162378760"
                                    startContent={<FontAwesomeIcon icon={faPhone} />}
                                    variant="bordered"
                                >
                                    (816) 237-8760
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>

            <Spacer y={20} />

            <FAQ />
        </div>
    )
}