import "./badge-options.css"
import { Accordion, AccordionItem, Button, ButtonGroup, Checkbox, CheckboxGroup, Input } from "@nextui-org/react";
import { useContext } from "react";
import { BadgeContext } from "../../../Context/badge-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileImport } from "@fortawesome/free-solid-svg-icons";
import { cast, copyBadge, instanceOfIFaceImage, loadFile, uuidv4 } from "../../../utils";
import saveAs from "file-saver";
import Gallery from "./gallery";
import Help from "./help";
import { IBadgeTemplate } from "../../../Models/IBadgeTemplate";
import { appInsights } from "../../../App-Insights";
import useKeyboardCommands from "../../../Hooks/use-keyboard-commands";

export default function Options() {
    const {badge, setBadge} = useContext(BadgeContext);
    const setName = (name: string) => setBadge({...badge, name});
    
    const getValueForCheckboxes = () => {
        const selected = [];
        if(badge.landscape) selected.push('landscape');
        if(badge.lanyard) selected.push('lanyard');
        if(badge.linkSides) selected.push('link-sides');
        return selected;
    }

    const updateOptions = (x: string[]) => {
        setBadge({
            ...badge,
            landscape: x.includes('landscape'),
            lanyard: !x.includes('landscape') && x.includes('lanyard'),
            linkSides: x.includes('link-sides')
        })
    }

    const save = () => {
        var fileName = `${badge.name || 'mybadge'}-buildabadge.json`;
        var fileToSave = new Blob([JSON.stringify(badge)], {
            type: 'application/json'
        });        
        saveAs(fileToSave, fileName);

        appInsights.trackEvent({
            name: 'Export Badge'
        })
    }

    const load = () => {
        loadFile((file: any) => {
            var reader = new FileReader();
            reader.readAsText(file);
    
            reader.onload = readerEvent => {
                let badge = JSON.parse(readerEvent!.target!.result!.toString());
                setBadge(badge);
            }
        }, "application/JSON");

        appInsights.trackEvent({
            name: 'Import Badge'
        })
    }

    useKeyboardCommands({
            saveBadge: save,
            loadBadge: load
        }, [badge])

    const el = 
    <div id="badge-options-container">
        <div style={{display: 'flex', gap: '15px', alignItems: 'center'}}>
            <Input 
                value={badge.name} 
                onValueChange={setName} 
                label="Badge Name (optional)"
                size="md"
            />

            <div className="d-small">
                <Help />
            </div>
        </div>

        <ButtonGroup size="md">
            <SaveTemplate />
            <Gallery />
            <Button onClick={save} color="success" variant="flat" startContent={<FontAwesomeIcon icon={faFileExport}/>}>Export</Button>
            <Button onClick={load} color="primary" variant="flat" startContent={<FontAwesomeIcon icon={faFileImport}/>}>Import</Button>
        </ButtonGroup>

        <CheckboxGroup
            color="warning"
            value={getValueForCheckboxes()}
            onValueChange={updateOptions}
            orientation="horizontal"
        >
            <Checkbox value="landscape">
                Landscape
                <br />
                <span className="text-tiny text-default-500">Change orientation to landscape</span>
            </Checkbox>
            <Checkbox value="lanyard" isDisabled={badge.landscape}>
                Punch Hole
                <br />
                <span className="text-tiny text-default-500">Add hole for a lanyard</span>
            </Checkbox>
            <Checkbox value="link-sides">
                Link Sides
                <br />
                <span className="text-tiny text-default-500">Make front and back sides the same</span>
            </Checkbox>
        </CheckboxGroup>

        <div className="d-big" >
            <div style={{display: 'flex', justifyContent: 'end'}}>
                <Help />
            </div>
        </div>
    </div>

    return (
        <>
            <Accordion className="d-small">
                <AccordionItem title="Options">
                    {el}
                </AccordionItem>
            </Accordion>

            <div className="d-big">
                {el}
            </div>
        </>

    )
}

function SaveTemplate() {
    const {badge} = useContext(BadgeContext);

    if(process.env.NODE_ENV != 'development') return <></>

    const save = () => {
        var copy = copyBadge(badge);
        for (var object of copy.front.objects) {
            if(instanceOfIFaceImage(object)) object.url = object.url.replace(`${process.env.REACT_APP_ASSETS_PATH}/`, "")
        }
        for (var object of copy.back.objects) {
            if(instanceOfIFaceImage(object)) object.url = object.url.replace(`${process.env.REACT_APP_ASSETS_PATH}/`, "")
        }

        const template = cast<IBadgeTemplate>({
            id: uuidv4(),
            badge: copy,
            colors: [],
            types: []
        })

        var fileToSave = new Blob([JSON.stringify(template)], {
            type: 'application/json'
        });        
        saveAs(fileToSave, "template.json");
    }

    return (
        <Button color="secondary" onClick={save}>Save Template</Button>
    )
}