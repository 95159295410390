import "./badge-editor.css"
import { useContext, useEffect, useState } from "react";
import { OptionsContext } from "../../../Context/options-context";
import { BadgeContext } from "../../../Context/badge-context";
import { IBadgeObject, IFace, IFaceImage, IFaceShape, IFaceText, IPosition } from "../../../Models/IBadge";
import { FaceContext } from "../../../Context/face-context";
import FaceEditor from "./face/face-editor";
import { Tab, Tabs } from "@nextui-org/react";
import { EditContext } from "../../../Context/edit-context";
import { EditContextLargeMenu, EditContextSmallMenu } from "./menu/edit-context";
import { cast, copyFace, instanceOfIFace, instanceOfIFaceText, instanceOfIPosition, loadImage, uuidv4 } from "../../../utils";
import { ShapeType } from "../../../Models/ShapeType";
import useKeyboardCommands from "../../../Hooks/use-keyboard-commands";
import { ImageType } from "../../../Models/ImageType";
import { appInsights } from "../../../App-Insights";

export default function BadgeEditor() {
    const {options} = useContext(OptionsContext);
    const {badge, setBadge} = useContext(BadgeContext);
    const [editContext, setEditContext] = useState<IBadgeObject | null>(null);
    const [selectedFace, setSelectedFace] = useState<IFace>({...badge.front});
    const [selectedTab, setSelectedTab] = useState('Front');

    const isLargeScreen = window.screen.width >= 1100;

    const setFace = (face: IFace) => {
        if(selectedFace.id == badge.front.id)
            setBadge({...badge, front: face})
        else 
            setBadge({...badge, back: face})
    }

    const resetFace = () => {
        var face = {id: uuidv4(), objects: []};

        if(selectedFace.id == badge.front.id) {
            setBadge({...badge, front: face})
        }
        else {
            setBadge({...badge, back: face})
        }
    }

    useEffect(() => {
        if(editContext == null) return;

        if (editContext.id == badge.front.id) {
            badge.front = editContext as IFace;
        } else if (editContext.id == badge.back.id) {
            badge.back = editContext as IFace;
        } else {
            var index = selectedFace.objects.findIndex(x => x.id == editContext.id);
            selectedFace.objects[index] = editContext;
        }

        setBadge({...badge})
    }, [editContext]);

    useEffect(() => {
        if(editContext?.id) {
            appInsights.trackEvent({
                name: 'Edit Context Changed'
            })
        }
    }, [editContext?.id])

    useEffect(() => {
        if(selectedTab == 'Front') 
            setSelectedFace({...badge.front})
        else 
            setSelectedFace({...badge.back})

        if (editContext == null) return;
        if (editContext.id != selectedFace.id && selectedFace.objects.findIndex(x => x.id == editContext.id) == -1) {
           setEditContext(null);
        }    
    }, [badge]);

    useEffect(() => {
        if(badge.linkSides) {
            setSelectedTab('Front');
            setBadge({...badge, back: {id: uuidv4(), objects: []}});

        // only copy to back if the back was empty 
        } else if(badge.back.objects.length == 0) {
            setBadge({...badge, back: copyFace(badge.front)});
        }
    }, [badge.linkSides])

    useEffect(() => {
        if(options.locked) setEditContext(null);
    }, [options.locked]);

    useEffect(() => {
        var face = selectedTab == 'Front' || badge.linkSides ? {...badge.front} : {...badge.back};
        setSelectedFace(face);
        setEditContext(isLargeScreen ? face : null);
    }, [selectedTab, badge.linkSides]);

    const calculateZindex = () => {
        let max = 5;
        for(var object of selectedFace.objects) {
            if(instanceOfIPosition(object)) max = Math.max(max, object.zIndex)
        }
        return max;
    }

    const addImage = (type: ImageType) => {
        loadImage((url: string) => {
            const obj = cast<IFaceImage>(
                {
                    id: uuidv4(),
                    url,
                    xPercentage: 50,
                    yPercentage: 50,
                    width: 200,
                    height: null,
                    zIndex: calculateZindex(),
                    borderWidth: 0,
                    avatar: type == ImageType.Avatar,
                    borderRadius: type == ImageType.Rounded ? 20 : undefined
                }
            );
            selectedFace.objects.push(obj)
            setFace({...selectedFace});
            setEditContext(obj);
        })   

        appInsights.trackEvent({
            name: 'Add Image',
            properties: {
                type: type
            }
        })
    }

    const addText = (fontFamily?: string | null) => {
        const obj  = cast<IFaceText>(
            {
                id: uuidv4(),
                content: 'Text',
                xPercentage: 50,
                yPercentage: 50,
                textAlign: 'center',
                zIndex: calculateZindex(),
                width: 100,
                height: null,
                borderWidth: 0,
                fontSize: 32,
                fontFamily
            }
        );
        selectedFace.objects.push(obj)
        setFace({...selectedFace});
        setEditContext(obj);

        appInsights.trackEvent({
            name: 'Add Text',
            properties: {
                fontFamily: fontFamily
            }
        })
    }

    const addShape = (shape: ShapeType) => {
        const obj  = cast<IFaceShape>(
            {
                id: uuidv4(),
                shape,
                backgroundColor: 'gray',
                xPercentage: 50,
                yPercentage: 50,
                zIndex: calculateZindex(),
                width: 100,
                height: 100,
                borderWidth: 0
            }
        );
        selectedFace.objects.push(obj)
        setFace({...selectedFace});
        setEditContext(obj);

        appInsights.trackEvent({
            name: 'Add Shape',
            properties: {
                type: shape
            }
        })
    }

    const onBadgeObjectDeleted = () => {
        if (editContext == null) return;

        if(editContext.id == selectedFace.id) {
            var face  = {
                ...selectedFace,
                color: null,
                imageUrl: null,
                opacity: 100
            };
            setEditContext(face);
            setSelectedFace(face);
            setFace(face);
        } else {
            var index = selectedFace.objects.findIndex(x => x.id == editContext.id);
            selectedFace.objects.splice(index, 1);

            setEditContext(null)
            setFace(selectedFace);
        }
    }

    const onBadgeObjectCopied = () => {
        if(editContext == null) return;

        var index = selectedFace.objects.findIndex(x => x.id == editContext.id);
        const obj = selectedFace.objects[index] as IPosition;
        const cpy: IPosition = {...obj, id: uuidv4(), xPercentage: obj.xPercentage + 2, yPercentage: obj.yPercentage + 2};
        selectedFace.objects.push(cpy);
        setEditContext(cpy);
        setBadge({...badge})
    }

    useKeyboardCommands({
        paste: () => {
            if(instanceOfIFace(editContext) || editContext == null) return;
            onBadgeObjectCopied();
        },
        delete: () => {
            if(!instanceOfIFaceText(editContext)) onBadgeObjectDeleted();
        },
        forceDelete: onBadgeObjectDeleted,
        addText,
        addImage: () => addImage(ImageType.Basic),
        addShape: () => addShape(ShapeType.Square),
        moveUp: () => {
            if(!instanceOfIPosition(editContext)) return;
            editContext.yPercentage -= 1;
            setEditContext({...editContext});
        },
        moveDown: () => {
            if(!instanceOfIPosition(editContext)) return;
            editContext.yPercentage += 1;
            setEditContext({...editContext});
        },
        moveLeft: () => {
            if(!instanceOfIPosition(editContext)) return;
            editContext.xPercentage -= 1;
            setEditContext({...editContext});
        },
        moveRight: () => {
            if(!instanceOfIPosition(editContext)) return;
            editContext.xPercentage += 1;
            setEditContext({...editContext});
        }
    }, [editContext, selectedFace, addText, addImage, addShape, onBadgeObjectCopied, onBadgeObjectDeleted, selectedTab, badge])

    return (
        <div id="badge-editor" onClick={() => setEditContext(null)}>
            <FaceContext.Provider value={{face: selectedFace, setFace, resetFace, addText, addImage, addShape}}>
                <EditContext.Provider value={{editContext, setEditContext, onDelete: onBadgeObjectDeleted, onCopy: onBadgeObjectCopied}}>                        
                    {isLargeScreen 
                    ? (
                        <div id="edit-menu-large-container">
                            <EditContextLargeMenu />
                        </div>
                    )
                    :  (
                        <div id="edit-menu-small-container" onClick={e => e.stopPropagation()}>
                            <EditContextSmallMenu />
                        </div>
                    )}

                    <div>
                        <Tabs classNames={{ base: 'face-tabs'}} 
                            size="lg" 
                            color="primary" 
                            selectedKey={selectedTab}
                            onSelectionChange={k => setSelectedTab(k.toString())}
                        >
                            <Tab key="Front" title="Front">
                                <FaceEditor />
                            </Tab>
                            <Tab key="Back" title="Back">
                                {badge.linkSides
                                ? (
                                    <div className="text-center border border-warning rounded-xl p-2" style={{maxWidth: '400px', margin: 'auto'}}>
                                        To edit the back, disable <span className="font-bold">"Link Sides"</span> in the options above 
                                    </div>
                                ) 
                                : <FaceEditor />
                                }
                            </Tab>
                        </Tabs>
                    </div>
                </EditContext.Provider>
            </FaceContext.Provider>
        </div>
    )
}