import { faCheck, faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { getCookie, setCookie } from "../../../utils";

export default function Email(props: {
    setEmail: any,
    isValidEmail: boolean,
    onVerified: any
}) {
    const SavedEmail = getCookie('email');
    const [email, setEmail] = useState(SavedEmail || '');
    const [verifiedEmail, setVerifiedEmail] = useState(SavedEmail != null);
    const [verifyingEmail, setVerifyingEmail] = useState(false);

    useEffect(() => {
        setVerifyingEmail(false);
        if(verifiedEmail) {
            setCookie('email', email)
            props.onVerified()
        }
    }, [verifiedEmail])

    useEffect(() => {
        props.setEmail(email);
        setVerifiedEmail(email == SavedEmail);
    }, [email])

    return (
        <>
            <Input 
                label="Email"
                description={!verifiedEmail 
                    ?
                        <div>
                            First time? <Link size="sm" onClick={() => setVerifyingEmail(true)}>Verify email</Link> for a <span className="font-bold">First Badge Discount</span>
                        </div>
                    :
                        "Your email address has been verified"
                }                
                startContent={<FontAwesomeIcon icon={faEnvelope} />}
                type="email" 
                value={email} 
                onValueChange={setEmail} 
                isRequired
            />

            {verifyingEmail && 
                <EmailVerification 
                    email={email}
                    setEmail={setEmail}
                    isValidEmail={props.isValidEmail}
                    onCancel={() => setVerifyingEmail(false)}
                    onVerified={() => setVerifiedEmail(true)}
                />
            }
        </>
    )
}

function EmailVerification(props: {
    email: string,
    setEmail: any,
    isValidEmail: boolean,
    onCancel: any,
    onVerified: any
}) {    
    const [isLoading, setIsLoading] = useState(false);
    const [sent, setSent] = useState(false);

    useEffect(() => {
        if (isLoading) setSent(false);
    }, [isLoading])

    const startVerification = async () => {
        try {
            setIsLoading(true);

            var res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/start-verify-identity`, {
                method: 'POST',
                body: JSON.stringify({
                    email: props.email
                })
            });
            
            if(!res.ok) throw new Error();

            setSent(true);
        }
        catch {
            // todo 
        } 
        finally {
            setIsLoading(false);
        }
    }

    if(sent) return <CodeVerification 
        email={props.email} 
        onCancel={() => setSent(false)} 
        onVerified={props.onVerified}
    />

    return (
        <Modal isOpen={true} onClose={props.onCancel} placement="center">
            <ModalContent>
            <ModalHeader>Verify Email</ModalHeader>
            <ModalBody>
                <div>
                    <Input 
                        label="Email"
                        description="Enter your email to receive a verification code"
                        startContent={<FontAwesomeIcon icon={faEnvelope} />}
                        type="email" 
                        value={props.email} 
                        onValueChange={props.setEmail} 
                        isRequired
                    />

                    {sent && (
                        <blockquote className="text-center border px-4 py-3 rounded-xl [&>p]:m-0 border-warning-100 bg-warning-50/20 my-2">
                            An email has been sent to your account. Click the link in the email to verify your account. 
                        </blockquote> 
                    )}
                </div>                     
            </ModalBody>

            <ModalFooter>
                <Button onClick={props.onCancel} color="danger">Cancel</Button>

                <Button 
                    startContent={<FontAwesomeIcon icon={faLock}/>}
                    color="primary"
                    onClick={startVerification}
                    isLoading={isLoading}
                    isDisabled={!props.isValidEmail}
                >
                    Send Verification Code
                </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    )
}

function CodeVerification(props: {
    email: string,
    onCancel: any,
    onVerified: any
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [failed, setFailed] = useState(false);
    const [code, setCode] = useState('');

    useEffect(() => {
        setFailed(false);
    }, [code])

    const verify = async () => {
        try {
            setIsLoading(true);

            var res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/verify-identity`, {
                method: 'POST',
                body: JSON.stringify({
                    email: props.email,
                    code
                })
            });
            
            if(!res.ok) throw new Error();

            props.onVerified();
        } catch {
            setFailed(true);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal isOpen={true} onClose={props.onCancel} placement="center">
            <ModalContent>
            <ModalHeader>Verify Email</ModalHeader>
            <ModalBody>
                <div>
                    <blockquote className="text-center border px-4 py-3 rounded-xl [&>p]:m-0 border-warning-100 bg-warning-50/20 my-2">
                        A code has been sent to {props.email}
                    </blockquote> 

                    <Spacer y={3} />

                    <Input 
                        label="Code"
                        value={code}
                        onValueChange={setCode}
                        errorMessage={failed && "Invalid code"}
                        isInvalid={failed}
                    />
                </div>                     
            </ModalBody>

            <ModalFooter>
                <Button onClick={props.onCancel} color="danger">Cancel</Button>

                <Button 
                    startContent={<FontAwesomeIcon icon={faCheck}/>}
                    color="primary"
                    onClick={verify}
                    isLoading={isLoading}
                    isDisabled={code == ''}
                >
                    Verify
                </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    )
}