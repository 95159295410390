import { createContext } from "react";
import { IOptions } from "../Models/IOptions";
import { ValueChanged } from "../Models/Types";

export const OptionsContext = createContext<{
    options: IOptions,
    setOptions: ValueChanged<IOptions>
}>({
    options: {
        locked: true
    },
    setOptions: () => {}
})