import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { IBadge } from "../../../Models/IBadge";
import { useContext, useEffect } from "react";
import { BadgeContext } from "../../../Context/badge-context";
import BadgeList from "../../../Components/badge-list";
import { appInsights } from "../../../App-Insights";

export default function Gallery() {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {setBadge} = useContext(BadgeContext);

    useEffect(() => {
        if(isOpen) {
            appInsights.trackEvent({
                name: 'Gallery Opened'
            })
        }
    }, [isOpen])

    const cancel = () => {
        appInsights.trackEvent({
            name: 'Gallery Closed'
        })
        onClose();
    }

    return(
        <>
            <Button 
                onClick={onOpen}
                startContent={<FontAwesomeIcon icon={faSearch} />}
            >
                Browse
            </Button>

            <Modal isOpen={isOpen} onClose={cancel} scrollBehavior="inside" placement="center" size="2xl" backdrop="blur" classNames={{wrapper: "gallery-modal", backdrop: "gallery-modal"}}>
                <ModalContent>
                    <ModalHeader>
                        <div>
                            Browse (beta)
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <BadgeList />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={cancel}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}