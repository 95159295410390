import { Card, Spacer } from "@nextui-org/react";
import { useContext } from "react";
import { BadgeContext } from "../../../../Context/badge-context";
import { FaceContext } from "../../../../Context/face-context";
import Face from "./face";
import FaceControls from "./face-controls";
import { EditContext } from "../../../../Context/edit-context";

export default function FaceEditor() {
    const {badge} = useContext(BadgeContext);
    const {face} = useContext(FaceContext);
    const {setEditContext} = useContext(EditContext);

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div onClick={() => setEditContext(null)}>
                <Card>
                    <Face landscape={badge.landscape} lanyard={badge.lanyard} face={face} scollable={false}/>
                </Card>
            </div>
            
            <Spacer y={3} />

            <div>
                <FaceControls />
            </div>
        </div>
    )
}