import { useState, useEffect } from "react"

export default function useBadgeObjectId(faceId: string, badgeObjectId: string) {
    const [id, setId] = useState(`${faceId}-${badgeObjectId}`)

    useEffect(() => {
        setId(`${faceId}-${badgeObjectId}`)
    }, [faceId, badgeObjectId])

    return id;
}