import { Input, Slider } from "@nextui-org/react";
import { IPosition } from "../../../../Models/IBadge";
import { highlightValue } from "../../../../utils";
import { ValueChanged } from "../../../../Models/Types";

export default function RotationMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IPosition,
    onValueChanged: ValueChanged<IPosition>
}) {

    const setRotation = (value: string) => {
        let parsedValue = parseFloat(value);

        if (isNaN(parsedValue)) {
            parsedValue = 0;
        }

        props.onValueChanged({...props.value, rotation: parsedValue});
    }

    return (
        <div>
            <p className="text-tiny text-default-500 mb-1">Rotation</p>
            <div style={{display: 'flex', gap: '15px', alignItems: 'center'}}>
                <div>
                    <Input 
                        color={props.color}
                        size={props.size}
                        variant="bordered"
                        type="number"
                        value={(props.value.rotation || 0).toString()} 
                        onValueChange={setRotation} 
                        endContent={<div>&deg;</div>} 
                        onFocus={highlightValue}
                    /> 
                </div>

                <div style={{flexBasis: '50%'}}>
                    <Slider 
                        color={props.color}
                        step={1} 
                        maxValue={360} 
                        minValue={0} 
                        value={props.value.rotation || 0}
                        onChange={v => setRotation(v.toString())}
                    />
                </div>
            </div>
        </div>
    )
}