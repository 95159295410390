import { faHamburger } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, Image, Dropdown, DropdownTrigger, Button, DropdownMenu, DropdownItem, NavbarMenu, NavbarMenuItem, NavbarMenuToggle } from "@nextui-org/react";

export default function Nav() {
    return (
        <Navbar isBordered maxWidth="2xl">
            <NavbarBrand>
                <Link className="font-bold text-inherit" color="foreground" href="/">
                    <Image src="/logo.png" width={36}/>
                    <p className="font-bold text-inherit ml-2">Build A Badge</p>
                </Link>                
            </NavbarBrand>

            <NavbarContent justify="end" className="space-x-2">
                <NavbarItem className="d-big">
                    <Link href="/badges" className="text-white hover:text-sky-500">Badges</Link>
                </NavbarItem>
                <NavbarItem className="d-big">
                    <Link href="/build" className="text-white hover:text-sky-500">Build</Link>
                </NavbarItem>
                <NavbarItem className="d-big">
                    <Link href="/pricing" className="text-white hover:text-sky-500">Pricing</Link>
                </NavbarItem>
                <NavbarItem className="d-big">
                    <Link href="/contact" className="text-white hover:text-sky-500">Support</Link>
                </NavbarItem>

                <NavbarMenuToggle
                    className="d-small"
                />
            </NavbarContent>

            <NavbarMenu className="d-small space-y-2">
                <NavbarMenuItem>
                    <Link href="/badges" className="text-white text-2xl">Badges</Link>
                </NavbarMenuItem>
                <NavbarMenuItem>
                    <Link href="/build" className="text-white text-2xl">Build</Link>
                </NavbarMenuItem>
                <NavbarMenuItem>
                    <Link href="/pricing" className="text-whit text-2xl">Pricing</Link>
                </NavbarMenuItem>
                <NavbarMenuItem>
                    <Link href="/contact" className="text-white text-2xl">Support</Link>
                </NavbarMenuItem>
            </NavbarMenu>
        </Navbar>
    )
}