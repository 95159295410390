import { faCircleUser, faFill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionItem, Button, Dropdown, DropdownItem, DropdownMenu, DropdownSection, DropdownTrigger, Input, Tooltip } from "@nextui-org/react";
import { IFaceImage } from "../../../../Models/IBadge";
import { highlightValue, loadImage } from "../../../../utils";
import BorderMenu from "./border";
import PositionMenu from "./position";
import SizeMenu from "./size";
import LayerMenu from "./layer";
import PaddingMenu from "./padding";
import RotationMenu from "./rotation";
import ColorPicker from "./color-picker";
import { ValueChanged } from "../../../../Models/Types";

export default function FaceImageMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IFaceImage,
    onValueChanged: ValueChanged<IFaceImage>
}) {

    const replaceImage = (url: string) => {
        props.onValueChanged({...props.value, url, height: null}) 
    }

    const toggleAvatar = () => props.onValueChanged({...props.value, avatar: !props.value.avatar})
    const setOpacity = (opacity: number) => {
        if(opacity < 1 || isNaN(opacity)) opacity = 1;
        else if (opacity > 100) opacity = 100;

        props.onValueChanged({
            ...props.value,
            opacity
        })
    }
    const setBackgroundColor = (backgroundColor: string) => props.onValueChanged({...props.value, backgroundColor})

    return (
        <div style={{display: 'grid', gap: '25px'}}>
            <div style={{display: 'flex', gap: '15px'}}>
                <ReplaceImage size={props.size} color={props.color} setUrl={replaceImage}/>

                <Tooltip content="Avatar">
                    <Button size={props.size} color={props.color} isIconOnly onClick={toggleAvatar} variant={props.value.avatar ? 'faded' : 'solid'}><FontAwesomeIcon icon={faCircleUser} size="lg"/></Button>
                </Tooltip>

                <ColorPicker tooltip="Fill Color" size={props.size} color={props.color} icon={faFill} value={props.value.backgroundColor} onValueChange={setBackgroundColor} />

                <LayerMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
            </div>

            <div>
                <p className="text-tiny text-default-500 mb-2">Opacity</p>
                <Input 
                    size={props.size}
                    color={props.color}
                    variant="bordered"
                    value={(props.value.opacity || 100).toString()} 
                    onValueChange={v => setOpacity(parseFloat(v))} 
                    onFocus={highlightValue}
                />
            </div>   

            <Accordion selectionMode="multiple">
                <AccordionItem title="Border">
                    <BorderMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
                    <div style={{marginTop: '15px'}}></div>
                    <PaddingMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />

                </AccordionItem>
                <AccordionItem title="Position">
                    <PositionMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
                </AccordionItem>
                <AccordionItem title="Rotation">
                    <RotationMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
                </AccordionItem>
                <AccordionItem title="Size">
                    <SizeMenu size={props.size} color={props.color} value={props.value} onValueChanged={x => props.onValueChanged(x as IFaceImage)} />
                </AccordionItem>
            </Accordion>        
        </div>
    )
}


function ReplaceImage(props: {
    color: any,
    size: "sm" | "md" | "lg",
    setUrl: any
}) {

    const replaceImage = () => {
        loadImage((url: string) => {
            props.setUrl(url)
        })   
    }

    if(process.env.NODE_ENV != 'development') return (
        <Button size={props.size} color={props.color} onClick={replaceImage}>Change Image</Button>
    )

    const people = [
        "headshot-man-2.png",
        "headshot-man-transparent.png",
        "headshot-man.png",
        "headshot-woman-casual.png",
        "headshot-woman-transparent.png",
        "headshot-woman.png",
        "man-full.jpg",
        "woman-casual-2.png"
    ]

    const backgrounds = [
        "blue-background.jpg",
        "gray-background-2.jpg",
        "gray-background.jpg",
        "orange-background.jpg",
        "pink-background-2.jpg",
        "pink-clouds.jpg",
        "tile-background.jpg",
        "white-background.jpg",
    ]

    const logos = [
        "acme-2.png",
        "acme-just-logo.png",
        "acme-man-transparent.png",
        "acme.png",
    ]

    const scannables = [
        "barcode.png",
        "qr-code-transparent.png",
        "qr-code.png",
    ]

    const setImage = (url: string) => {
        props.setUrl(`${process.env.REACT_APP_ASSETS_PATH}/images/${url}`)
    }

    const dropdownMenu = (menuName: string, images: string[]) => (
        <DropdownSection title={menuName}>
            {images.map((image, i) => (
                <DropdownItem key={i} onClick={() => setImage(image)}>{image}</DropdownItem>
            ))}
        </DropdownSection>
    )

    return (
        <Dropdown>
            <DropdownTrigger>
                <Button>Change Image</Button>
            </DropdownTrigger>
            <DropdownMenu className="dropdown">
               {dropdownMenu("People", people)}
               {dropdownMenu("Scannables", scannables)}
               {dropdownMenu("Logos", logos)}
               {dropdownMenu("Backgrounds", backgrounds)}
            </DropdownMenu>
        </Dropdown>
    )    
}