import { CircularProgress } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { getTemplates } from "../../utils";
import { useEffect, useState } from "react";
import { IBadge } from "../../Models/IBadge";
import Build from "./Components/Build";

export default function BuildFromTemplate() {
    const params = useParams();
    const {data} = useSWR('templates', () => getTemplates());
    const [badge, setBadge] = useState<IBadge | null>(null);

    useEffect(() => {
        if(data == null) return;

        var templateId = params['templateId']!;

        var template = data.filter(x => x.id.toLowerCase() == templateId.toLowerCase())[0];

        setBadge(template.badge);
    }, [data])

    if(badge == null) return <CircularProgress style={{margin: 'auto'}}/>

    return <Build badge={badge} /> 
}