import './Build.css'
import { Button, Spacer } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { IBadge } from '../../../Models/IBadge';
import { BadgeContext } from '../../../Context/badge-context';
import { OptionsContext } from '../../../Context/options-context';
import { IOptions } from '../../../Models/IOptions';
import Options from './badge-options';
import BadgeEditor from './badge-editor';
import Saver from './saver';
import AddToCart from './cart/add-to-cart';
import Downloader from './downloader';

export default function Build(props: {badge: IBadge}) {
    const [badge, setBadge] = useState<IBadge>(props.badge);
    const [options, setOptions] = useState<IOptions>({
        locked: false
    })

    useEffect(() => {
        setBadge(props.badge);
    }, [props.badge])

    return(
        <BadgeContext.Provider value={{badge, setBadge}}>
            <OptionsContext.Provider value={{options, setOptions}}>
                <Display />
                <Saver />
            </OptionsContext.Provider>
        </BadgeContext.Provider>
    )
}


function Display() {
    const [showMissingFeatures, setShowMissingFeatures] = useState(true);

    return (
        <div style={{paddingTop: '30px', paddingLeft: '30px', paddingRight: "30px", paddingBottom: '250px'}}>
            <div className='py-3 px-2 bg-foreground-50 border border-default-300'>
                <Options />
            </div>

            {showMissingFeatures && (
                <div className='d-small'>
                    <Spacer y={10} />
                    <div className='text-center border border-danger rounded-xl p-3'>
                        You're missing out on features. Consider using a computer for all features.

                        <Button
                            onClick={() => setShowMissingFeatures(false)}
                            fullWidth
                            className='mt-2'
                            variant='bordered'
                            color='default'
                        >
                            Close
                        </Button> 
                    </div>
                </div>
            )}

            <Spacer y={10} />

            <div className='border px-4 py-3 rounded-xl border-default-300 bg-foreground-50/20'>
                <BadgeEditor />
            </div>

            <div id="checkout-button-container" className='border-t border-warning-300 bg-foreground-50/90'>
                <div>
                    <AddToCart />

                    <Spacer y={2}/>

                    <div className="text-center">
                        <Downloader />
                    </div>
                </div>
            </div>
        </div>
    )
}