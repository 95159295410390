import { createContext } from "react";
import { IBadge } from "../Models/IBadge";
import { uuidv4 } from "../utils";
import { ValueChanged } from "../Models/Types";


export const BadgeContext = createContext<
{
    badge: IBadge,
    setBadge: ValueChanged<IBadge>
}
>({
    badge: {
        landscape: false,
        lanyard: false,
        linkSides: false,
        front: {
            id: uuidv4(),
            objects: []
        },
        back: {
            id: uuidv4(),
            objects: []
        }
    },
    setBadge: () => {}
});