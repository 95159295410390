import { IPadding } from "../../../../Models/IBadge";
import { Input} from "@nextui-org/react";
import { highlightValue } from "../../../../utils";
import { ValueChanged } from "../../../../Models/Types";

export default function PaddingMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IPadding,
    onValueChanged: ValueChanged<IPadding>
}) {
    const reset = () => props.onValueChanged({...props.value, horizontalPadding: 0, verticalPadding: 0})
    const setPadding = (padding: number) => props.onValueChanged({...props.value, horizontalPadding: padding, verticalPadding: padding})
    const setHorizontalPadding = (horizontalPadding: number) => props.onValueChanged({...props.value, horizontalPadding})
    const setVerticalPadding = (verticalPadding: number) => props.onValueChanged({...props.value, verticalPadding})


    return (
        <div>
            <p className="text-tiny text-default-500 mb-1">Padding</p>
            <Input
                size={props.size}
                color={props.color}
                type="number"
                variant="bordered"
                onFocus={highlightValue}
                value={(props.value.horizontalPadding || 0).toString()}
                onValueChange={v => setPadding(parseFloat(v))}
            />
        </div>
    )
}