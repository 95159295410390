import { Card } from "@nextui-org/react";
import { CSSProperties } from "react";
import { Square, Triangle, Circle, RightRightTriangle, CurvedTriangle, InvertedCurvedTriangle, DoubleCurvedTriangle, Bowl, QuarterPipe, LeftRightTriangle, UpsideDownTriangle, TopRightRightTriangle, TopLeftRightTriangle, LeftTriangle, RightTriangle, TopLeftCircleQuadrant, BottomLeftCircleQuadrant, BottomRightCircleQuadrant, TopRightCircleQuadrant, BottomSemiCircle, LeftSemiCircle, RightSemiCircle, TopSemiCircle } from "../../../../Components/shapes";
import { ShapeType } from "../../../../Models/ShapeType";
import { ValueChanged } from "../../../../Models/Types";

export default function AddShapesMenu(props: {addShape: ValueChanged<ShapeType>}) {
    const color = 'skyblue'
    const styles: CSSProperties = {
        width: '75px',
        height: '75px',
        backgroundColor: color,
        margin: '10px'
    }

    return (
        <div style={{maxHeight: '600px', overflowY: 'scroll'}}>
            <div>
                <div className="text-2xl mb-5">Popular</div>

                <div style={{display: 'flex', gap: '25px', flexWrap: 'wrap'}}>
                    <Card className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.Square)}>
                        <Square styles={styles}/>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.Circle)}>
                        <Circle styles={styles} />
                    </Card>

                    <Card className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.Triangle)} >
                        <div id="triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <Triangle color={color} parentId={"triangle-shape"} />
                        </div>
                    </Card>
                </div>
            </div>

            <div className="mt-5">
                <div className="text-2xl mb-5">All</div>

                <div className="my-5" style={{display: 'flex', gap: '25px', flexWrap: 'wrap'}}>
                    <Card className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.LeftTriangle)} >
                        <div id="triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <LeftTriangle color={color} parentId={"triangle-shape"} />
                        </div>
                    </Card>

                    <Card className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.RightTriangle)} >
                        <div id="triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <RightTriangle color={color} parentId={"triangle-shape"} />
                        </div>
                    </Card>

                    <Card className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.UpsideDownTriangle)} >
                        <div id="upside-down-triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <UpsideDownTriangle color={color} parentId={"upside-down-triangle-shape"} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.RightRightTriangle)} >
                        <div id="right-triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <RightRightTriangle color={color} parentId={"right-triangle-shape"} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.LeftRightTriangle)} >
                        <div id="left-right-triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <LeftRightTriangle color={color} parentId={"left-right-triangle-shape"} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.TopLeftRightTriangle)} >
                        <div id="left-right-triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <TopLeftRightTriangle color={color} parentId={"left-right-triangle-shape"} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.TopRightRightTriangle)} >
                        <div id="left-right-triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <TopRightRightTriangle color={color} parentId={"left-right-triangle-shape"} />
                        </div>
                    </Card>
                    

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.CurvedTriangle)} >
                        <div id="curved-triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <CurvedTriangle color={color} parentId={"curved-triangle-shape"} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.InvertedCurvedTriangle)} >
                        <div id="inv-curved-triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <InvertedCurvedTriangle color={color} parentId={"inv-curved-triangle-shape"} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.DoubleCurvedTriangle)} >
                        <div id="double-curved-triangle-shape" style={{...styles, backgroundColor: undefined}}>
                            <DoubleCurvedTriangle color={color} parentId={"double-curved-triangle-shape"} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.TopLeftCircleQuadrant)}>
                        <div id="circle-quad" style={{...styles, backgroundColor: undefined}}>
                            <TopLeftCircleQuadrant color={color} parentId={"circle-quad"} styles={styles} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.TopRightCircleQuadrant)}>
                        <div id="circle-quad" style={{...styles, backgroundColor: undefined}}>
                            <TopRightCircleQuadrant color={color} parentId={"circle-quad"} styles={styles} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.BottomLeftCircleQuadrant)}>
                        <div id="circle-quad" style={{...styles, backgroundColor: undefined}}>
                            <BottomLeftCircleQuadrant color={color} parentId={"circle-quad"} styles={styles} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.BottomRightCircleQuadrant)}>
                        <div id="circle-quad" style={{...styles, backgroundColor: undefined}}>
                            <BottomRightCircleQuadrant color={color} parentId={"circle-quad"} styles={styles} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.TopSemiCircle)}>
                        <div id="semi-circle" style={{...styles, backgroundColor: undefined}}>
                            <TopSemiCircle color={color} parentId={"semi-circle"} styles={styles} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.BottomSemiCircle)}>
                        <div id="semi-circle" style={{...styles, backgroundColor: undefined}}>
                            <BottomSemiCircle color={color} parentId={"semi-circle"} styles={styles} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.LeftSemiCircle)}>
                        <div id="semi-circle" style={{...styles, backgroundColor: undefined}}>
                            <LeftSemiCircle color={color} parentId={"semi-circle"} styles={styles} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.RightSemiCircle)}>
                        <div id="semi-circle" style={{...styles, backgroundColor: undefined}}>
                            <RightSemiCircle color={color} parentId={"semi-circle"} styles={styles} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.QuarterPipe)}>
                        <div id="quarter-pipe" style={{...styles, backgroundColor: undefined}}>
                            <QuarterPipe color={color} parentId={"quarter-pipe"} styles={styles} />
                        </div>
                    </Card>

                    <Card  className="bg-slate-700" isPressable onClick={() => props.addShape(ShapeType.Bowl)}>
                        <div id="bowl" style={{...styles, backgroundColor: undefined}}>
                            <Bowl color={color} parentId={"bowl"} styles={styles} />
                        </div>
                    </Card>
                </div>
            </div>

        </div>
    )
}