import { useParams } from "react-router-dom";
import { IAddress, IOrder, IOrderItem, IShippingAddress } from "../../Models/IOrder";
import { Accordion, AccordionItem, Button, Card, CardBody, Checkbox, CircularProgress, Image, Link, Spacer } from "@nextui-org/react";
import { useState, useEffect } from "react";
import useSWR from "swr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const USDollar =  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});


const getOrder = async (orderId: string): Promise<IOrder> => {
    let res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/order/${orderId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(!res.ok) throw new Error();

    var orderJson = await res.json();

    return orderJson;
}

export default function Order() {
    const params = useParams();
    const {data} = useSWR('order', () => getOrder(params['orderId']!));
    const [order, setOrder] = useState<IOrder | null>(null);

    useEffect(() => {
        if(data == null) return;
        setOrder(data);
    }, [data])

    if(order == null) return <CircularProgress style={{margin: 'auto'}}/>

    return <OrderViewer order={order} /> 
}

function OrderViewer(props: {order: IOrder}) {
    return (
        <div className="p-4 lg:p-10">
            
            <div className="mb-2">
                <span>{props.order.id}</span>
            </div>

            <div className="mb-2">
                <span className="mr-1"><FontAwesomeIcon icon={faCalendar} /></span>
                <span>{new Date(Date.parse(props.order.orderDate)).toDateString()}</span>
            </div>

            <div className="mb-2">
                <span className="mr-1">Total:</span>
                <span>{props.order.total ? USDollar.format(props.order.total / 100) : 'N/A'}</span>
            </div>

            <Accordion variant="bordered">
                <AccordionItem title="Shipping Info">
                    <Shipping order={props.order} />
                </AccordionItem>

                <AccordionItem title={<div>Badges ({props.order.items.length})</div>}>
                    <BadgeViewers badges={props.order.items} />
                </AccordionItem>
            </Accordion>

        </div>
    )
}

function Shipping(props: {order: IOrder}) {
    return (
        <div>
            {props.order.trackingNumber && (
                <div className="text-lg mb-5 space-y-2">
                    <Button as={Link} href={`https://tools.usps.com/go/TrackConfirmAction?tref=fullpage&tLabels=${props.order.trackingNumber}`} target="_" showAnchorIcon={true}>Track Package</Button>
                </div>
            )}

            <div className="space-y-2">
                <div>{props.order.shippingAddress.name}</div>
                <div>{props.order.shippingAddress.address.addressLine1}</div>
                <div>{props.order.shippingAddress.address.addressLine2}</div>
                <div>{props.order.shippingAddress.address.city}, {props.order.shippingAddress.address.state}</div>
                <div>{props.order.shippingAddress.address.postalCode}</div>
                <div>{props.order.shippingAddress.address.country}</div>
            </div>
        </div>
    )
}



function BadgeViewers(props: {badges: IOrderItem[]}) {
    return (
        <div className="flex gap-10 flex-wrap justify-center items-center">
            {props.badges.map((badge, i) => (
                <div key={i}>
                    <Card>
                        <CardBody>
                            <div>
                                <div className="mb-2">
                                    <div className="text-center mb-2 text-lg font-semibold">{badge.name}</div>
                                    <div className="flex justify-center gap-10">
                                        <div>Copies: {badge.quantity}</div>
                                        <Checkbox isSelected={badge.lanyard} >Lanyard</Checkbox>
                                    </div>
                                </div>
                                <div className="flex gap-10 justify-center">
                                    <Image
                                        src={badge.frontImageUrl} 
                                        className="w-[300px]"
                                    />
                                    <Image
                                        src={badge.backImageUrl} 
                                        className="w-[300px]"
                                    />
                                </div>
                            </div>

                        </CardBody>
                    </Card>

                    
                </div>
            ))}
        </div>
    )
}