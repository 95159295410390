import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faEraser, faFont, faMountainSun, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionItem, Button, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer, useDisclosure } from "@nextui-org/react";

export default function Help() {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <>
            <Button 
                variant="flat"
                color="primary"
                startContent={<FontAwesomeIcon icon={faQuestionCircle}/>}
                onClick={onOpen}
                size="md"
            >
                Help
            </Button>


            <Modal isOpen={isOpen} onClose={onClose} placement="center" size="2xl" classNames={{wrapper: "add-shape-modal", backdrop: "add-shape-modal"}}>
                <ModalContent>
                    <ModalHeader>Help</ModalHeader>
                    <ModalBody>
                        <div className="text-center flex justify-center">
                            <Link href="/privacy" className="mr-2" showAnchorIcon target="_">Privacy Policy</Link>
                            <Link href="/contact" target="_" showAnchorIcon>Support</Link>
                        </div>

                        <Accordion selectionMode="multiple">
                            <AccordionItem className="d-big" title={<div className="font-bold uppercase">Keyboard Shortcuts</div>}>
                                <KeyboardShortcuts />
                            </AccordionItem>
                        </Accordion>

                        <ModalFooter>
                            <Button onClick={onClose}>Close</Button>
                        </ModalFooter>


                        {/* <Accordion selectionMode="multiple">
                            <AccordionItem title={<div className="font-bold uppercase">How To</div>}>
                                <Accordion variant="bordered">
                                    <AccordionItem title="Basics">
                                        <div>
                                            Click 

                                            <Button 
                                                startContent={<FontAwesomeIcon icon={faEraser}/>}
                                                color="danger"
                                                isIconOnly
                                                className="mx-1"
                                                size="sm"
                                            >
                                            </Button>

                                            <span className="font-semibold">below</span> the badge to reset the current side of badge
                                        </div>
                                    </AccordionItem>
                                    <AccordionItem title="Edit Background">
                                        <div>
                                            Click
                                            <Button 
                                                startContent={<FontAwesomeIcon icon={faMountainSun}/>}
                                                color="default"
                                                isIconOnly
                                                size="sm"
                                                className="mx-1"
                                            >
                                            </Button>
                                            for available background options
                                        </div>
                                        <div className="text-tiny text-default-500">Image and Color</div>
                                    </AccordionItem>
                                    <AccordionItem title="Add/Edit Images">
                                        <div>
                                            Click
                                            <Button 
                                                startContent={<FontAwesomeIcon icon={faImage}/>}
                                                color="primary"
                                                isIconOnly
                                                className="mx-1"
                                                size="sm"
                                            >
                                            </Button>
                                            to add an image to the current side of the badge
                                        </div>

                                        <Spacer y={3} />

                                        Select the added image for available options 

                                        <div className="text-tiny text-default-500">Resizing, Aligning, Moving, and Rotating</div>
                                    </AccordionItem>

                                    <AccordionItem title="Add/Edit Text">
                                        <div>
                                            Click
                                            <Button 
                                                startContent={<FontAwesomeIcon icon={faFont}/>}
                                                color="secondary"
                                                isIconOnly
                                                className="mx-1"
                                                size="sm"
                                            >
                                            </Button>
                                            to add text to the current side of the badge
                                        </div>

                                        <Spacer y={3} />
                                        Select the added text for available options 
                                        <div className="text-tiny text-default-500">Styling, Font, Aligning, Moving, and Rotating</div>
                                    </AccordionItem>
                                </Accordion>                    
                            </AccordionItem>
                            <AccordionItem title={<div className="font-bold uppercase">Badge Specs</div>}>
                                <div>
                                    Badges are the same size as a credit card 
                                    <div className="text-tiny text-default-500">3.375 inches wide by 2.125 inches high</div>
                                </div>

                                <Spacer y={2} />

                                <div>
                                    Badges do not have a magnetic strip.
                                </div>
                            </AccordionItem>
                            <AccordionItem title={<div className="font-bold uppercase">Uses</div>}>
                                <div>
                                    Build A Badge should be used to create badges for work or entertainment.
                                    <br />
                                    <span className="text-default-500">Examples: McLovin, Employee Badge</span>

                                    <Spacer y={2} />

                                    It should <span className="font-bold">not</span> be used for official government identification or illicit content. 
                                    Doing so may result in the authorities being contacted. 
                                </div>
                            </AccordionItem>
                        </Accordion> */}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

function KeyboardShortcuts() {

    const shortcuts = [
        {
            keyCombo: 'Backspace',
            description: 'Delete selected object (except text)'
        },
        {
            keyCombo: 'CTRL + Backspace',
            description: 'Force delete (including text)'
        },
        {
            keyCombo: 'CTRL + C',
            description: 'Copy selected object'
        },
        {
            keyCombo: 'CTRL + V',
            description: 'Paste selected object'
        },
        {
            keyCombo: 'CTRL + Z',
            description: 'Undo change'
        },
        {
            keyCombo: 'CTRL + L',
            description: 'Load Badge'
        },
        {
            keyCombo: 'CTRL + S',
            description: 'Save Badge'
        },
        {
            keyCombo: 'CTRL + SHIFT + A',
            description: 'Add default text'
        },
        {
            keyCombo: 'CTRL + SHIFT + I',
            description: 'Add default image'
        },
        {
            keyCombo: 'CTRL + SHIFT + S',
            description: 'Add square'
        },
        {
            keyCombo: 'Arrow Up',
            description: 'Move selected object up'
        },
        {
            keyCombo: 'Arrow Down',
            description: 'Move selected object down'
        },
        {
            keyCombo: 'Arrow Left',
            description: 'Move selected object left'
        },
        {
            keyCombo: 'Arrow Right',
            description: 'Move selected object right'
        }
    ]

    return (
        <div>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px'}}>
                {shortcuts.map((x, i) => (
                    <div key={i} className="bg-slate-700 p-2">
                        <div className="font-bold mb-1" style={{minWidth: '200px'}}>{x.keyCombo}</div>
                        <div>{x.description}</div>
                    </div>
                ))}
                
            </div>
        </div>
    )
}