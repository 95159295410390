import { IFaceText } from "../../../../Models/IBadge";
import ResizerArrows from "./resizer-arrows";
import CurrentPositionWindow from "./current-position-window";
import useBorderStyles from "./hooks/border-hook";
import usePositionStyles from "./hooks/position-hook";
import useSizeStyles from "./hooks/size-hook";
import usePaddingStyles from "./hooks/padding-hook";
import RotateBox from "./rotate-box";
import useBadgeObjectId from "./hooks/badge-object-id-hook";
import useSelectable from "./hooks/selectable-hook";


export default function FaceText(props: {faceId: string, value: IFaceText}) {
    const {selected, selectable, onSelect} = useSelectable(props.value);
    const id = useBadgeObjectId(props.faceId, props.value.id);

    const positionStyles = usePositionStyles(props.value);
    const borderStyles = useBorderStyles(selected, props.value);
    const paddingStyles = usePaddingStyles(props.value);
    const sizeStyles = useSizeStyles(props.value);

    return (
        <div id={id} 
            style={{
                ...positionStyles,
                ...borderStyles,
                ...paddingStyles,
                ...sizeStyles,
                minWidth: 'max-content',
                backgroundColor: props.value.backgroundColor || 'unset',
                boxSizing: 'border-box',
            }}
            onClick={onSelect}
            className={selectable ? 'badge-object-selectable' : ''}
        >
                <div
                    id={`${id}-text`} 
                    style={{
                        letterSpacing: props.value.letterSpacing ? `${props.value.letterSpacing}px` : '0.01px', // Needed for html2canvas to work properly 
                        whiteSpace: 'pre-wrap',
                        fontFamily: props.value.fontFamily || 'Arial',
                        fontSize: props.value.fontSize ? `${props.value.fontSize}px` : '2em',
                        color: props.value.color || 'black',
                        fontWeight: props.value.bold ? 'bold' : 'normal',
                        textDecoration: props.value.underline ? 'underline' : 'none',
                        fontStyle: props.value.italic ? 'italic' : 'unset',
                        textAlign: props.value.textAlign as any || 'unset',
                        height: '100%',
                        width: '100%',
                        alignContent: 'center',
                        lineHeight: `${props.value.lineHeight}`,
                    }}
                >
                    {!props.value.spaceWordsEvenly && props.value.content}
                    {props.value.spaceWordsEvenly && (
                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            {props.value.content?.split(' ').map((x, i) => <span key={i}>{x}</span>)}
                        </div>
                    )}
                </div>

                <RotateBox visible={selected} />
                <ResizerArrows visible={selected} />
                {selected && <CurrentPositionWindow id={id}/>}
        </div>
    )
}