import { Button, ButtonGroup, Tooltip } from "@nextui-org/react"
import { IPosition } from "../../../../Models/IBadge"
import { faAnglesUp, faAnglesDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ValueChanged } from "../../../../Models/Types"

export default function LayerMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IPosition,
    onValueChanged: ValueChanged<IPosition>
}) {
    const layerUp = () => props.onValueChanged({...props.value, zIndex: props.value.zIndex + 1})
    const layerDown = () => props.onValueChanged({...props.value, zIndex: Math.max(0, props.value.zIndex - 1)})


    return (
        <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
            <ButtonGroup color={props.color} size={props.size}>
                <Tooltip content="Layer Down">
                    <Button onClick={layerDown} isIconOnly isDisabled={props.value.zIndex == 0}><FontAwesomeIcon icon={faAnglesDown} size="lg"/></Button>
                </Tooltip>
                <>
                    <span className={`p-2 bg-${props.color}`}>{props.value.zIndex}</span>
                </>
                <Tooltip content="Layer Up">
                    <Button onClick={layerUp} isIconOnly><FontAwesomeIcon icon={faAnglesUp} size="lg"/></Button>
                </Tooltip>
            </ButtonGroup>
        </div>
    )
}