import { useDisclosure, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@nextui-org/react";
import saveAs from "file-saver";
import { useContext, useState, useEffect } from "react";
import { BadgeContext } from "../../../Context/badge-context";
import { OptionsContext } from "../../../Context/options-context";
import { getFaceImages } from "../../../utils";
import BadgePreviewer from "./cart/badge-previewer";
import { appInsights } from "../../../App-Insights";

export default function Downloader() {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {options, setOptions} = useContext(OptionsContext);
    const {badge} = useContext(BadgeContext);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setOptions({...options, locked: isOpen})

        if(isOpen) {
            appInsights.trackEvent({
                name: 'Download Design Opened'
            })
        }
    }, [isOpen])

    const cancel = () => {
        appInsights.trackEvent({
            name: 'Download Design Cancelled'
        })

        onClose();
    }

    const download = async () => {
        setLoading(true);

        try {
            appInsights.trackEvent({
                name: 'Download Design'
            })

            var images = await getFaceImages("badge-previewer-container");

            var baseFileName = `${badge.name || 'mybadge'}`;      
            saveAs(images[0], `${baseFileName}-front.png`);
            saveAs(images[1], `${baseFileName}-back.png`);

            onClose();
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Button
                variant="bordered"
                onClick={onOpen}
            >
                Download Design
            </Button>

            <Modal isOpen={isOpen} onClose={cancel} placement="center" backdrop="blur"  size="3xl" scrollBehavior="inside"  classNames={{wrapper: 'checkout-modal', backdrop: 'checkout-modal-backdrop'}}>
                <ModalContent>
                    <ModalHeader>Download Design</ModalHeader>
                    <ModalBody>
                        <div className="p-2 text-center" style={{margin: 'auto', maxWidth: '400px'}}>
                            Need to print your badge? Add your badge to your cart and we will print and ship the badge to you. 
                        </div>

                        <BadgePreviewer />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={download}
                            color="primary"
                            isLoading={loading}
                        >
                            Download
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}