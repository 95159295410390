import { faBug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@nextui-org/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { appInsights } from "../App-Insights";

export default function ErrorBoundary(
) {
    const error = useRouteError()

    useEffect(() => {
        appInsights.trackException({
            exception: new Error(error as string)
        })
    }, [error])

    return (
        <div className="p-4 space-y-5 text-center" style={{}}>

            <div><FontAwesomeIcon icon={faBug} style={{fontSize: '50px'}} /></div>

            <div className="text-xl">A problem has been encountered, sorry for the inconvenience</div>

            <div className="text-xl">
                You can <Link className="text-xl" href="/contact">contact support</Link> or reload the page
            </div>
        </div>
    )
}