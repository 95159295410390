import { createContext } from "react";
import { IFace } from "../Models/IBadge";
import { uuidv4 } from "../utils";
import { ShapeType } from "../Models/ShapeType";
import { EventEmitter, ValueChanged } from "../Models/Types";
import { ImageType } from "../Models/ImageType";

export const FaceContext = createContext<{
    face: IFace,
    setFace: ValueChanged<IFace>,
    resetFace: EventEmitter,
    addText: ValueChanged<string | undefined | null>,
    addImage: ValueChanged<ImageType>,
    addShape: ValueChanged<ShapeType>
}>({
    face: {
        id: uuidv4(),
        objects: [],
    },
    setFace: () => {},
    resetFace: () => {},
    addImage: () => {},
    addShape: () => {},
    addText: () => {}
});