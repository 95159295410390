import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css'
import CheckoutSuccess from "./Pages/CheckoutSuccess"
import { NextUIProvider } from '@nextui-org/system';
import Nav from './Components/nav';
import PrivacyPolicy from './Pages/Privacy';
import About from './Pages/About/About';
import { Badges } from './Pages/Badges/Badges';
import Contact from './Pages/Contact';
import Checkout from './Pages/Checkout/Checkout';
import BuildFromTemplate from './Pages/Builder/BuildFromTemplate';
import BuildFromScratch from './Pages/Builder/BuildFromScratch';
import Footer from './Components/footer';
import { Spacer } from '@nextui-org/react';
import Pricing from './Pages/Pricing/Pricing';
import ErrorBoundary from './Components/error-boundary';
import { useEffect } from 'react';
import { appInsights } from './App-Insights';
import Order from './Pages/Order/Order';

const router = createBrowserRouter([
    {
        path: '*',
        Component: About,
        errorElement: <ErrorBoundary />
    },
    {
      path: "build",
      Component: BuildFromScratch,
      errorElement: <ErrorBoundary />
    },
    {
        path: "build/:templateId",
        Component: BuildFromTemplate,
        errorElement: <ErrorBoundary />
    },
    {
        path: 'success',
        Component: CheckoutSuccess
    },
    {
        path: 'privacy',
        Component: PrivacyPolicy
    },
    {
        path: 'badges',
        Component: Badges,
    },
    {
        path: 'contact',
        Component: Contact
    },
    {
        path: 'checkout',
        Component: Checkout,
        errorElement: <ErrorBoundary />
    },
    {
        path: 'pricing',
        Component: Pricing
    },
    {
        path: 'order/:orderId',
        Component: Order,
        errorElement: <ErrorBoundary />
    }
]);

export default function App() {
    useEffect(() => {
        appInsights.trackPageView({
            name: window.location.pathname
        })
    }, [])

    return (
        <NextUIProvider>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <Nav />
                <div style={{flexGrow: '1'}}>
                    <div id="container">
                        <RouterProvider router={router} />
                    </div>
                </div>

                <Spacer y={20} />
                <Footer />
            </div>
        </NextUIProvider>
    )
}