import { createContext } from "react";
import { IBadgeObject } from "../Models/IBadge";
import { ValueChanged } from "../Models/Types";

export const EditContext = createContext<{
    editContext: IBadgeObject | null,
    setEditContext: ValueChanged<IBadgeObject | null>,
    onDelete: any,
    onCopy: any
}>({
    editContext: null,
    setEditContext: () => {},
    onDelete: () => {},
    onCopy: () => {}
});