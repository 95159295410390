import { faCartPlus, faPlus, faShoppingCart, faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Link, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer, useDisclosure } from "@nextui-org/react";
import BadgePreviewer from "./badge-previewer";
import { useContext, useEffect, useState } from "react";
import { OptionsContext } from "../../../../Context/options-context";
import { cast, getFaceImages, uuidv4 } from "../../../../utils";
import { IBadgeCartItem } from "../../../../Models/ICart";
import { BadgeContext } from "../../../../Context/badge-context";
import useCart from "../../../../Hooks/use-cart";
import Preview from "./preview";
import { appInsights } from "../../../../App-Insights";

const USDollar =  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export default function AddToCart() {
    const {cart, addPrice, cartLoading, saveCart} = useCart();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [loading, setLoading] = useState(false);
    const {options, setOptions} = useContext(OptionsContext);
    const {badge} = useContext(BadgeContext);
    const [cartMaxed, setCartMaxed] = useState(false);

    useEffect(() => {
        setOptions({...options, locked: isOpen})

        if(isOpen) {
            appInsights.trackEvent({
                name: 'Add To Cart Opened'
            })
        }
    }, [isOpen])

    useEffect(() => {
        setCartMaxed(cart?.items.length == 200)
    }, [cart])

    const cancel = () => {
        appInsights.trackEvent({
            name: 'Add To Cart Cancelled'
        })

        onClose();
    }

    const add = async () => {
        try {
            if(cart == null) return;

            setLoading(true);

            appInsights.trackEvent({
                name: 'Add To Cart'
            })

            var images = await getFaceImages("badge-previewer-container");

            const formData = new FormData();
            formData.append("front", images[0]);
            formData.append("back", images[1]);

            var res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/badge/upload`, {
                method: 'POST',
                body: formData
            });

            if(!res.ok) throw new Error();

            var uploadBadgeJson = await res.json()

            cart.items.push(cast<IBadgeCartItem>({
                ...uploadBadgeJson,
                id: uuidv4(),
                quantity: 1,
                landscape: badge.landscape,
                lanyard: badge.lanyard,
                name: badge.name || "My Badge"
            }))

            await saveCart({
                ...cart
            });

            onClose();
        } catch(err) {
            appInsights.trackException(err as any)
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <ButtonGroup className="text-lg" size="lg" fullWidth isDisabled={cart == null}>
                <Button 
                    startContent={<FontAwesomeIcon icon={faPlus}/>}
                    color="warning"
                    onClick={onOpen}
                    isLoading={cartLoading}
                    isDisabled={cart == null}
                >
                    Cart
                    {/* Cart ({USDollar.format(addPrice)}) */}
                </Button>
                <Button 
                    startContent={<FontAwesomeIcon icon={faShoppingCart}/>}
                    color="warning"
                    as={Link}
                    href="/checkout"
                    variant="bordered"
                    isDisabled={cart == null}
                    onClick={() => {
                        appInsights.trackEvent({
                            name: 'Checkout'
                        })
                    }}
                >
                    {cart == null || cart.items.length == 0 ? 'Cart Empty' : `Checkout (${(cart?.items.length || 0)})`}
                </Button>
            </ButtonGroup>

            <Modal isOpen={isOpen} onClose={cancel} placement="center"  size="3xl" scrollBehavior="inside" classNames={{wrapper: 'checkout-modal', backdrop: 'checkout-modal-backdrop'}} backdrop="blur">
                <ModalContent>
                    <ModalHeader>Add To Cart</ModalHeader>
                    <ModalBody>
                        <BadgePreviewer />
                        <Spacer y={2} />
                        <Preview />
                        {cartMaxed && (
                            <div>
                                <Spacer y={2} />
                                <div className="text-center p-2 border">
                                    Maximum number of unique badges reached for an order.
                                    <Spacer />
                                    Please checkout and start a new order to keep adding unique badges.
                                    <Spacer />
                                    <span className="text-sm text-default-500">Bulk Order discount will still apply on followup order(s)</span>
                                </div>
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                        <Button 
                            as={Link}
                            href="/pricing"
                            target="_blank"
                            showAnchorIcon={true}
                            variant="bordered"
                            color="primary"
                        >
                            Pricing
                        </Button>
                        <Button 
                            isLoading={loading}
                            color="warning"
                            startContent={<FontAwesomeIcon icon={faCartPlus}/>}
                            onClick={add}
                            isDisabled={cartMaxed}
                        >
                            Add to Cart
                            {/* Add to Cart ({USDollar.format(addPrice)}) */}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

