import { IBowlShape, IFaceShape } from "../../../../Models/IBadge"
import useBorderStyles from "./hooks/border-hook";
import usePaddingStyles from "./hooks/padding-hook";
import usePositionStyles from "./hooks/position-hook";
import useSizeStyles from "./hooks/size-hook";
import CurrentPositionWindow from "./current-position-window";
import ResizerArrows from "./resizer-arrows";
import RotateBox from "./rotate-box";
import { Square, Circle, Triangle, RightRightTriangle, CurvedTriangle, InvertedCurvedTriangle, DoubleCurvedTriangle, Bowl, QuarterPipe, LeftRightTriangle, UpsideDownTriangle, TopLeftRightTriangle, TopRightRightTriangle, LeftTriangle, RightTriangle, TopLeftCircleQuadrant, BottomLeftCircleQuadrant, BottomRightCircleQuadrant, TopRightCircleQuadrant, BottomSemiCircle, LeftSemiCircle, RightSemiCircle, TopSemiCircle } from "../../../../Components/shapes";
import useBadgeObjectId from "./hooks/badge-object-id-hook";
import useSelectable from "./hooks/selectable-hook";
import { ShapeType } from "../../../../Models/ShapeType";

export default function FaceShape(props: {
    faceId: string,
    value: IFaceShape
}) {
    const {selected, selectable, onSelect} = useSelectable(props.value);
    const id = useBadgeObjectId(props.faceId, props.value.id);

    const positionStyles = usePositionStyles(props.value);
    const borderStyles = useBorderStyles(selected, props.value);
    const paddingStyles = usePaddingStyles(props.value);
    const sizeStyles = useSizeStyles(props.value);

    return (
        <div 
            id={id}
            style={{
                ...positionStyles,
                border: selected ? 'dashed 1px black' : undefined,
                ...paddingStyles,
                ...sizeStyles,
                opacity: (props.value.opacity || 100) / 100
            }}
            
            className={selectable ? 'badge-object-selectable face-shape' : 'face-shape'}
        >
            {props.value.shape == ShapeType.Square && <Square onClick={onSelect} class="shape" styles={{backgroundColor: props.value.backgroundColor, ...borderStyles}} /> }
            
            {props.value.shape == ShapeType.Circle && <Circle onClick={onSelect} class="shape" styles={{backgroundColor: props.value.backgroundColor, ...borderStyles}} /> }
            
            {props.value.shape == ShapeType.TopLeftCircleQuadrant && <TopLeftCircleQuadrant onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles}  parentId={id} /> }
            {props.value.shape == ShapeType.TopRightCircleQuadrant && <TopRightCircleQuadrant onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles}  parentId={id} /> }
            {props.value.shape == ShapeType.BottomLeftCircleQuadrant && <BottomLeftCircleQuadrant onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles}  parentId={id} /> }
            {props.value.shape == ShapeType.BottomRightCircleQuadrant && <BottomRightCircleQuadrant onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles}  parentId={id} /> }

            {props.value.shape == ShapeType.QuarterPipe && <QuarterPipe onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles}  parentId={id} /> }

            {props.value.shape == ShapeType.TopSemiCircle && <TopSemiCircle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles}  parentId={id} /> }
            {props.value.shape == ShapeType.BottomSemiCircle && <BottomSemiCircle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles}  parentId={id} /> }
            {props.value.shape == ShapeType.LeftSemiCircle && <LeftSemiCircle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles}  parentId={id} /> }
            {props.value.shape == ShapeType.RightSemiCircle && <RightSemiCircle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles}  parentId={id} /> }

            {props.value.shape == ShapeType.Bowl && <Bowl onClick={onSelect} color={props.value.backgroundColor} radiusOffset={(props.value as IBowlShape).radiusOffset} class="shape" styles={borderStyles}  parentId={id} /> }
            
            {props.value.shape == ShapeType.Triangle && <Triangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
            {props.value.shape == ShapeType.LeftTriangle && <LeftTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
            {props.value.shape == ShapeType.RightTriangle && <RightTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
            {props.value.shape == ShapeType.UpsideDownTriangle && <UpsideDownTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
            
            {props.value.shape == ShapeType.RightRightTriangle && <RightRightTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
            {props.value.shape == ShapeType.LeftRightTriangle && <LeftRightTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
            {props.value.shape == ShapeType.TopLeftRightTriangle && <TopLeftRightTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
            {props.value.shape == ShapeType.TopRightRightTriangle && <TopRightRightTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
           
            {props.value.shape == ShapeType.CurvedTriangle && <CurvedTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
            {props.value.shape == ShapeType.InvertedCurvedTriangle && <InvertedCurvedTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }
            {props.value.shape == ShapeType.DoubleCurvedTriangle && <DoubleCurvedTriangle onClick={onSelect} color={props.value.backgroundColor} class="shape" styles={borderStyles} parentId={id}/> }

            <RotateBox visible={selected} />
            <ResizerArrows visible={selected} />
            {selected && <CurrentPositionWindow id={id}/>}
        </div>
    )
}

