import { CSSProperties, useState, useEffect } from "react";

function useParentSize(parentId: string) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            const entry = entries[0];
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);
        })

        resizeObserver.observe(document.getElementById(parentId)!);

        return () => resizeObserver.disconnect();
    }, [parentId])

    return { width, height}
}

export function Square(props: {styles?: CSSProperties, class?: string, onClick?: any}) {
    return (
        <div className={props.class} style={{width: '100%', height: '100%', ...props.styles}} onClick={props.onClick}>
        </div>
    )
}

export function Circle(props: {styles?: CSSProperties, class?: string, onClick?: any}) {
    return (
        <div className={props.class} style={{width: '100%', height: '100%', ...props.styles, borderRadius: '50%'}} onClick={props.onClick}>

        </div>
    )
}

export function TopLeftCircleQuadrant(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r2 = Math.pow(width, 2);

        for(var x = 1; x < width; x++) {
            let y = Math.sqrt(r2 - Math.pow(x, 2));
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${points} ${width},0`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function TopRightCircleQuadrant(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r2 = Math.pow(width, 2);

        for(var x = 1; x < width; x++) {
            let y = Math.sqrt(r2 - Math.pow(x - width, 2));
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${points} ${width},0`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function BottomLeftCircleQuadrant(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r2 = Math.pow(width, 2);

        for(var x = 1; x <= width; x++) {
            let y = height - Math.sqrt(r2 - Math.pow(x, 2));
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${points} 0,${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function BottomRightCircleQuadrant(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r2 = Math.pow(width, 2);

        for(var x = 1; x <= width; x++) {
            let y = height - Math.sqrt(r2 - Math.pow(x - width, 2));
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,${height} ${points} ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function QuarterPipe(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r2 = Math.pow(width, 2);

        for(var x = 1; x < width; x++) {
            let y = Math.sqrt(r2 - Math.pow(x, 2));
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,${height} ${points} ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function TopSemiCircle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r2 = Math.pow(width / 2, 2);

        for(var x = 1; x < width; x++) {
            let y = Math.sqrt(r2 - Math.pow(x - (width / 2), 2));
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${points} ${width},0`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function BottomSemiCircle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r2 = Math.pow(width / 2, 2);

        for(var x = 1; x < width; x++) {
            let y = height - Math.sqrt(r2 - Math.pow(x - (width / 2), 2));
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,${height} ${points} ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function LeftSemiCircle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r2 = Math.pow(height / 2, 2);

        for(var y = 1; y < height; y++) {
            let x = Math.sqrt(r2 - Math.pow(y - (height / 2), 2));
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${points} 0,${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function RightSemiCircle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r2 = Math.pow(height / 2, 2);

        for(var y = 1; y < height; y++) {
            let x = width - Math.sqrt(r2 - Math.pow(y - (height / 2), 2));
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`${width},0 ${points} ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function Bowl(props: {parentId: string, color: string, radiusOffset?: number, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let r = width + (props.radiusOffset || 0)

        let r2 = Math.pow(r / 2, 2);

        for(var x = 1; x < width; x++) {
            let y = Math.sqrt(r2 - Math.pow(x - (width / 2), 2));

            y -= (props.radiusOffset || 0) * .9

            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height, props.radiusOffset])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,${height} ${points} ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function Triangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,${height} ${width / 2},0 ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function LeftTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${width},${height / 2} 0,${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function RightTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`${width},0 0,${height / 2} ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
            </svg>
    )
}

export function UpsideDownTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${width / 2},${height} ${width},0`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function RightRightTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,${height} ${width},0 ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function LeftRightTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${0},${height} ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function TopLeftRightTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${width},0 0,${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function TopRightRightTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${width},0 ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function CurvedTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let max = Math.pow(width, 2);
        let points = '';

        for(var x = 1; x < width; x++) {
        
            var y = height - (Math.pow(x, 2) / max) * height;
    
            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,${height} ${points} ${width},0 ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function InvertedCurvedTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [points, setPoints] = useState('');

    useEffect(() => {
        let points = '';
        let max = Math.pow(width, 2);

        for(var x = 1; x < width; x++) {
            
            var y = height - (Math.pow(width - x, 2) / max) * height;

            points += ` ${x},${y}`
        }

        setPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,0 ${points} ${width},${height} 0,${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )
}

export function DoubleCurvedTriangle(props: {parentId: string, color: string, class?: string, styles?: CSSProperties, onClick?: any}) {
    const {width, height} = useParentSize(props.parentId);
    const [leftPoints, setLeftPoints] = useState('');
    const [rightPoints, setRightPoints] = useState('');

    useEffect(() => {
        let max = Math.pow(width, 2);
        let points = '';

        for(var x = 1; x < width / 2; x++) {
        
            var y = height - (Math.pow(x * 2, 2) / max) * height;
    
            points += ` ${x},${y}`
        }

        setLeftPoints(points)
    }, [width, height])

    useEffect(() => {
        let max = Math.pow(width, 2);
        let points = '';

        for(var x = width / 2; x < width; x++) {
            
            var y = height - (Math.pow((width - x) * 2, 2) / max) * height;

            points += ` ${x},${y}`
        }

        setRightPoints(points)
    }, [width, height])

    return (
        <svg width={width} height={height} >
            <polygon onClick={props.onClick} points={`0,${height} ${leftPoints} ${width / 2},0 ${rightPoints} ${width},${height}`} fill={props.color} className={props.class} style={props.styles}/>
        </svg>
    )

    return (
        <div style={{display: 'flex', width: '100%', height: '100%'}}>
            <div id={`${props.parentId}-left`} style={{width: '50%', height: '100%'}}>
                <CurvedTriangle onClick={props.onClick} color={props.color} parentId={`${props.parentId}-left`}/>
            </div>
            <div id={`${props.parentId}-right`} style={{width: '50%', height: '100%'}}>
                <InvertedCurvedTriangle onClick={props.onClick} color={props.color} parentId={`${props.parentId}-right`}/>
            </div>
        </div>
    )
}


