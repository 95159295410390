import "./badge-list.css"
import { Skeleton, Chip, Spacer, Card, Button, ButtonGroup } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { IBadgeTemplate } from "../Models/IBadgeTemplate";
import Face from "../Pages/Builder/Components/face/face";
import { getTemplates, shuffle } from "../utils";
import useSWR from "swr";
import { OptionsContext } from "../Context/options-context";

export default function BadgeList() {
    const {data, isLoading} = useSWR('templates', () => getTemplates());
    const [allTemplates, setAllTemplates] = useState<IBadgeTemplate[]>([]);

    useEffect(() => {
        if(data == null) return;
        setAllTemplates(data)
        setAllTemplates(shuffle(data));
    }, [data])

    const [templates, setTemplates] = useState<IBadgeTemplate[]>([]);
    const [filterLandscape, setFilterLandscape] = useState<boolean | null>(null);

    useEffect(() => {
        let templates = [...allTemplates];

        if(filterLandscape != null) templates = templates.filter(x => x.badge.landscape == filterLandscape);
 
        setTemplates(templates)
    }, [allTemplates, filterLandscape])
    
    return isLoading 
    ? (
        <div>
            <div className="filters">
                {[...Array(3)].map((e, i) => (
                    <Skeleton key={i} className="flex rounded-full">
                        <Chip size="lg">Test</Chip>
                    </Skeleton>
                ))}
            </div>
            <Spacer y={5} />
            <div className="badges-grid">
                {[...Array(9)].map((e, i) => (
                    <Card key={i} className="w-3/12 space-y-5 p-4" radius="lg">
                        <Skeleton className="rounded-lg">
                            <div className="h-24 rounded-lg bg-default-300"></div>
                        </Skeleton>
                        <div className="space-y-3">
                            <Skeleton className="w-3/5 rounded-lg">
                                <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="w-4/5 rounded-lg">
                                <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                            </Skeleton>
                            <Skeleton className="w-2/5 rounded-lg">  
                                <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                            </Skeleton>
                        </div>
                    </Card>  
                ))}
            </div>
        </div>
    )
    : (
        <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div>
                    <div className="text-sm text-default-500 mb-1">Orientation</div>
                    <ButtonGroup>
                        <Button variant={filterLandscape == null ? 'solid' : 'bordered'} onClick={() => setFilterLandscape(null)}>All</Button>
                        <Button variant={filterLandscape == false ? 'solid' : 'bordered'} onClick={() => setFilterLandscape(false)}>Portrait</Button>
                        <Button variant={filterLandscape == true ? 'solid' : 'bordered'} onClick={() => setFilterLandscape(true)}>Landscape</Button>
                    </ButtonGroup>
                </div>
            </div>

            <Spacer y={5} />

            <div className="text-sm text-center text-default-500">{templates.length} badges</div>
            <Spacer y={5} />

            <div className="badges-grid">
                <OptionsContext.Provider value={{
                    options: {
                        locked: true
                    },
                    setOptions: () => {}
                }}>
                    {templates.map((template, i) => (
                        <Card key={i} isPressable onClick={() => window.location.assign(`/build/${template.id}`)}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Face face={template.badge.front} landscape={template.badge.landscape} lanyard={template.badge.lanyard} scollable={true}/>
                            </div>
                        </Card>
                    ))}
                </OptionsContext.Provider>
                
            </div>
        </div>
    )
}