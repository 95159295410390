import { faImage } from "@fortawesome/free-regular-svg-icons";
import { faFont, faEraser, faShapes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, useDisclosure, Modal, ModalContent, ModalBody, ModalHeader, ModalFooter } from "@nextui-org/react";
import { useContext } from "react";
import { FaceContext } from "../../../../Context/face-context";
import Undo from "../undo";
import AddShapesMenu from "../menu/add-shapes";
import { ShapeType } from "../../../../Models/ShapeType";
import { ImageType } from "../../../../Models/ImageType";

export default function FaceControls() {
    const {resetFace, addText, addImage, addShape} = useContext(FaceContext); 

    return (
        <div style={{display: 'flex', gap: '20px'}}>
            <Button 
                startContent={<FontAwesomeIcon icon={faImage}/>}
                onClick={() => addImage(ImageType.Basic)}
                color="primary"
                isIconOnly
                className="d-small"
            />

            <Button 
                startContent={<FontAwesomeIcon icon={faFont}/>}
                onClick={() => addText(null)}
                color="secondary"
                isIconOnly
                className="d-small"
            />

            <AddShape />
            <Undo />
            <ClearFace onReset={resetFace} />            
        </div>
    )
}

function AddShape() {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {addShape} = useContext(FaceContext); 

    const addShapeHandler = (shape: ShapeType) => {
        addShape(shape)
        onClose();
    }

    return (
        <>
            <Button 
                startContent={<FontAwesomeIcon icon={faShapes}/>}
                onClick={onOpen}
                color="default"
                isIconOnly
                className="d-small"
            />

            <Modal isOpen={isOpen} onClose={onClose} backdrop="blur" placement="center" classNames={{wrapper: "add-shape-modal", backdrop: "add-shape-modal"}}>
                <ModalContent>
                    <ModalHeader>Shapes</ModalHeader>
                    <ModalBody>
                        <AddShapesMenu addShape={addShapeHandler} />
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose} color="default">Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

function ClearFace(props: {onReset: any}) {
    const {isOpen, onOpen, onClose} = useDisclosure();

    const reset = () => {
        props.onReset();
        onClose();
    }

    return (
        <>
            <Button 
                className="d-small"
                startContent={<FontAwesomeIcon icon={faEraser}/>}
                color="danger"
                onClick={onOpen}
                isIconOnly
            />
            <Button 
                className="d-big"
                startContent={<FontAwesomeIcon icon={faEraser}/>}
                color="danger"
                onClick={onOpen}
            >
                Clear
            </Button>
            

            <Modal isOpen={isOpen} onClose={onClose} backdrop="blur" placement="center">
                <ModalContent>
                    <ModalHeader>Clear</ModalHeader>
                    <ModalBody>
                        <span>Are you sure you want to clear this side?</span>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose} color="primary">No</Button>
                        <Button onClick={reset} color="danger" endContent={<FontAwesomeIcon icon={faEraser}/>}>Yes</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}