import { faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, Input } from "@nextui-org/react";
import { loadImage, highlightValue } from "../../../../utils";
import ColorPicker from "./color-picker";
import { IFace } from "../../../../Models/IBadge";
import { ValueChanged } from "../../../../Models/Types";

export default function FaceMenu(props: {
    color: any,
    size: "sm" | "md" | "lg", 
    value: IFace,
    onValueChanged: ValueChanged<IFace>
}) {
    const setImage = () => {
        loadImage((imageUrl: string) => {
            props.onValueChanged({
                ...props.value,
                imageUrl
            })
        })
    };

    const setColor = (color: string) => {
        props.onValueChanged({
            ...props.value,
            color
        })
    }

    const setOpacity = (opacity: number) => {
        if(opacity < 1 || isNaN(opacity)) opacity = 1;
        else if (opacity > 100) opacity = 100;

        props.onValueChanged({
            ...props.value,
            opacity
        })
    }

    return (
        <div style={{display: 'grid', gap: '25px'}}>
            <div style={{display: 'flex', gap: '15px', flexWrap: 'wrap'}}>
                <Tooltip content="Background Image">
                    <Button onClick={setImage} isIconOnly startContent={<FontAwesomeIcon icon={faImage}/>} />
                </Tooltip>
                <ColorPicker value={props.value.color} size={props.size} onValueChange={setColor} tooltip="Background Color" />
            </div>
            <div>
                <p className="text-tiny text-default-500 mb-1">Opacity</p>
                <Input
                    type="number"
                    size={props.size}
                    value={(props.value.opacity || 100).toString()}
                    onValueChange={v => setOpacity(parseFloat(v))}
                    onFocus={highlightValue}
                />
            </div>
        </div>
    )
}