import { useContext, useEffect, useState } from "react";
import { BadgeContext } from "../../../../Context/badge-context";
import { Spacer, Divider, Card } from "@nextui-org/react";
import { IFace } from "../../../../Models/IBadge";
import { copyBadge, instanceOfIFaceImage, instanceOfIFaceShape, instanceOfIFaceText } from "../../../../utils";
import useBadgeColors from "../../../../Hooks/use-badge-colors";


export default function Theme() {
    const {badge, setBadge} = useContext(BadgeContext);
    const colors = useBadgeColors();
    const [previousColors, setPreviousColors] = useState<string[]>([])

    const changeColor = (oldColor: string, newColor: string) => {
        const replace = (face: IFace) => {
            if(face.color == oldColor) face.color = newColor;

            for(var object of face.objects) {
                if (instanceOfIFaceText(object) && object.color == oldColor) {
                    object.color = newColor;
                }

                if ((instanceOfIFaceText(object) || instanceOfIFaceImage(object) || instanceOfIFaceShape(object))) {
                    if(object.backgroundColor == oldColor) object.backgroundColor = newColor;
                    if(object.borderColor == oldColor) object.borderColor = newColor;
                }
            }
        }

        replace(badge.front)
        replace(badge.back)

        setBadge(copyBadge(badge))
    }

    useEffect(() => {
        if(previousColors.length > 0) {
            for(var color of colors) {
                if(!previousColors.includes(color)) {
                    // color changes cause this menu to disappear, so we click the new input again (hacky solution)
                    document.getElementById(`theme-color-${color}`)?.click()
                    break;
                }
    
            }
        }

        setPreviousColors([...colors])
    }, [colors])

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <p className="text-lg text-default-1000">Edit Colors</p>
            </div>
            <Spacer y={2} />
            <Divider />
            <Spacer y={2} /> 

            <div style={{display: 'flex', gap: '10px', flexWrap: 'wrap'}}>
                {colors.map(color => (
                    <Card key={color} isPressable>
                        <div style={{position: 'relative', width: '50px', height: '50px', backgroundColor: color}}>
                            <input id={`theme-color-${color}`} className="color-picker" type="color" value={color} onChange={e => changeColor(color, e.target.value)} style={{opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%'}} />
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    )
}