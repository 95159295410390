import "./face.css"
import { IFace, IFaceImage, IFaceShape, IFaceText, IPosition, ISize } from "../../../../Models/IBadge";
import FaceText from "./face-text";
import FaceImage from "./face-image";
import { instanceOfIFaceImage, instanceOfIFaceText } from "../../../../utils";
import { Fragment, useContext } from "react";
import { EditContext } from "../../../../Context/edit-context";
import { OptionsContext } from "../../../../Context/options-context";
import FaceShape from "./face-shape";
import Interactable from "./interactable";

export default function Face(props: {landscape: boolean, lanyard: boolean, face: IFace, scollable: boolean}) {
    const {setEditContext} = useContext(EditContext);
    const {options} = useContext(OptionsContext);

    const clicked = (e:  React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(options.locked) return;

        e.stopPropagation();
        setEditContext(props.face);
    }

    return (
        <div 
            id={props.face.id} 
            className="face" 
            style={{touchAction: props.scollable ? 'auto' : 'none'}} 
            onClick={clicked}
        >
            <div className={props.landscape ? 'landscape' : 'portrait'} style={{position: 'relative'}}>
                {props.lanyard ? <div className="lanyard-hole" data-html2canvas-ignore></div> : null }

                <div className="face-image" style={{
                    backgroundImage: props.face.imageUrl ? `url(${props.face.imageUrl})` : '',
                    backgroundColor: props.face.color || '',
                    opacity: props.face.opacity ? props.face.opacity / 100 : 'unset'
                }}></div>

                {props.face.objects.map((obj, i) => (
                    <Fragment key={obj.id}>
                        <Interactable obj={obj as IPosition & ISize} >
                            {instanceOfIFaceText(obj)
                                ? 
                                    <FaceText 
                                        faceId={props.face.id} 
                                        value={obj as IFaceText} 
                                    />
                                :
                                instanceOfIFaceImage(obj)
                                ?
                                <FaceImage 
                                    faceId={props.face.id} 
                                    value={obj as IFaceImage}
                                />
                                :
                                <FaceShape 
                                    faceId={props.face.id} 
                                    value={obj as IFaceShape}
                                />
                            }
                        </Interactable>
                    </Fragment>
                ))}
            </div>
        </div>
       
    )
}



