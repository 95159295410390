import "./resize-arrows.css"
import { faCircleDot } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ResizerArrows(props: {visible: boolean}) {
    return (
        <>
            <div data-html2canvas-ignore className="resize-dot resize-left" style={{visibility: props.visible ? 'visible' : 'hidden', position: 'absolute', left: '0%', top: '50%', transform: 'translate(-50%, -50%)'}}>
            <FontAwesomeIcon icon={faCircleDot} />
            </div>
            <div data-html2canvas-ignore className="resize-dot resize-right" style={{visibility: props.visible ? 'visible' : 'hidden', position: 'absolute', right: '0%', top: '50%', transform: 'translate(50%, -50%)'}}>
            <FontAwesomeIcon icon={faCircleDot} />
            </div>
            <div data-html2canvas-ignore className="resize-dot resize-top" style={{visibility: props.visible ? 'visible' : 'hidden', position: 'absolute', left: '50%', top: '0%', transform: 'translate(-50%, -50%)'}}>
            <FontAwesomeIcon icon={faCircleDot} />
            </div>
            <div data-html2canvas-ignore className="resize-dot resize-bottom" style={{visibility: props.visible ? 'visible' : 'hidden', position: 'absolute', left: '50%', bottom: '0%', transform: 'translate(-50%, 50%)'}}>
            <FontAwesomeIcon icon={faCircleDot} />
            </div>

            <div data-html2canvas-ignore className="resize-dot resize-left resize-bottom" style={{visibility: props.visible ? 'visible' : 'hidden', position: 'absolute', left: '0%', top: '100%', transform: 'translate(-50%, -50%)'}}>
            <FontAwesomeIcon icon={faCircleDot} />
            </div>
            
            <div data-html2canvas-ignore className="resize-dot resize-left resize-top" style={{visibility: props.visible ? 'visible' : 'hidden', position: 'absolute', left: '0%', top: '0%', transform: 'translate(-50%, -50%)'}}>
            <FontAwesomeIcon icon={faCircleDot} />
            </div>

            <div data-html2canvas-ignore className="resize-dot resize-right resize-top" style={{visibility: props.visible ? 'visible' : 'hidden', position: 'absolute', right: '0%', top: '0%', transform: 'translate(50%, -50%)'}}>
            <FontAwesomeIcon icon={faCircleDot} />
            </div>

            <div data-html2canvas-ignore className="resize-dot resize-right resize-bottom" style={{visibility: props.visible ? 'visible' : 'hidden', position: 'absolute', right: '0%', bottom: '0%', transform: 'translate(50%, 50%)'}}>
            <FontAwesomeIcon icon={faCircleDot} />
            </div>
        </>
    )
}