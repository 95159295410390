import { Button, Chip, Image, Link, Spacer } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons";
import BadgeList from "../../Components/badge-list";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

export function Badges() {
    return (
        <div className="p-4 lg:p-10">
            <BadgesHeader image={`${process.env.REACT_APP_ASSETS_PATH}/images/professional.jpg`} name="Badges" />

            <Spacer y={10} />

            <BadgeList /> 

            <Spacer y={40} />

            <Footer />
        </div>
    )
}

function BadgesHeader(props: {image: string, name: string}) {

    return (
        <div className="bg-emerald-950 p-4 lg:p-10" style={{display: 'flex', justifyContent: 'center', gap: '150px'}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div className="text-3xl font-semibold">
                    Badges Built For You
                </div>

                <Spacer y={5} />

                <div style={{display: 'flex', gap: '5px', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Professional</Chip>
                    <Chip color="success"><FontAwesomeIcon icon={faCheck} className="mr-1"/> Customizable</Chip>
                </div>

                <Spacer y={5} />

                <div className="text-center text-lg" >
                    Browse our custom madge badges and choose the right one for you.
                    <Spacer/>
                    Every badge is customizable so you'll be able to change text, images, and branding. 
                    <Spacer />
                    <span className="text-sm text-default-500">New badges are added regularly</span>
                </div>

                
            </div>

            <div className="d-big">
                <Image 
                    src={`${process.env.REACT_APP_ASSETS_PATH}/images/holding-badge.png`}
                    className="w-[300px]"
                />
            </div>
        </div>
    )
}


function Footer() {
    return (
        <>
        <div className="text-black p-4 lg:p-10 bg-warning" style={{display: 'flex', flexWrap: 'wrap', gap: '30px', alignItems: 'center'}}>
            <div>
                <Image
                    src={`${process.env.REACT_APP_ASSETS_PATH}/images/custom.jpg`}
                    className="lg:h-[340px]"
                />
            </div>
            <div>
                <div className="lg:text-5xl text-3xl font-light" style={{margin: 'auto'}}>Build Your Own</div>
                <Spacer y={10} />
                <div>
                    Looks like you've reached the end but don't give up, you can still make your own. 
                    <Spacer y={2}/>
                    You can also contact us for more badge information and options.
                </div>
                <Spacer y={10} />
                <div>
                    <Button
                        color="warning"
                        variant="faded"
                        as={Link}
                        href="build"
                        size="lg"
                        className="mr-2"
                        endContent={<FontAwesomeIcon icon={faArrowRight}/>}
                    >
                        Start Building
                    </Button>

                    <Button
                        color="primary"
                        variant="solid"
                        as={Link}
                        href="contact"
                        size="lg"
                        endContent={<FontAwesomeIcon icon={faEnvelope}/>}
                    >
                        Contact Us
                    </Button>
                </div> 
            </div>
        </div>
        </>
    )
}

