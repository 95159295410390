import { useEffect } from "react";
import { EventEmitter } from "../Models/Types";

const KeysPressed: any = {};

export default function useKeyboardCommands(commands: {
    copy?: EventEmitter,
    paste?: EventEmitter,
    delete?: EventEmitter,
    forceDelete?: EventEmitter,
    undo?: EventEmitter,
    saveBadge?: EventEmitter,
    loadBadge?: EventEmitter,
    addText?: EventEmitter,
    addImage?: EventEmitter
    addShape?: EventEmitter,
    moveUp?: EventEmitter,
    moveDown?: EventEmitter,
    moveLeft?: EventEmitter,
    moveRight?: EventEmitter

}, deps: any[]) {

    const handleActions = (ev: KeyboardEvent) => {
        const performAction = (action?: EventEmitter, resetKey?: string, preventDefault?: boolean) => {
            if(action) { 
                action();

                // some actions don't allow keyup to happen. (e.g. opening a file)
                if(resetKey)
                    KeysPressed[resetKey] = false;
            }

            if(preventDefault) ev.preventDefault();
        }

        if(KeysPressed["meta"] || KeysPressed["control"]) {
            if(KeysPressed["c"]) performAction(commands.copy)
            if(KeysPressed["v"]) performAction(commands.paste)
            if(KeysPressed["z"]) performAction(commands.undo)
        }

        if(KeysPressed["backspace"]) performAction(commands.delete);
        if((KeysPressed["control"] && KeysPressed["backspace"]) || KeysPressed["delete"]) performAction(commands.forceDelete);

        if(KeysPressed["control"] && KeysPressed["shift"]) {
            if(KeysPressed["a"]) performAction(commands.addText, undefined, true)
            if(KeysPressed["i"]) performAction(commands.addImage, "i");
            if(KeysPressed["s"]) performAction(commands.addShape);
        }

        if(KeysPressed["control"] && KeysPressed["s"]) performAction(commands.saveBadge, undefined, true);
        if(KeysPressed["control"] && KeysPressed["l"]) performAction(commands.loadBadge, "l", true);

        // movement
        if(KeysPressed["arrowup"]) performAction(commands.moveUp, undefined, true);
        if(KeysPressed["arrowdown"]) performAction(commands.moveDown, undefined, true);
        if(KeysPressed["arrowleft"]) performAction(commands.moveLeft, undefined, true);
        if(KeysPressed["arrowright"]) performAction(commands.moveRight, undefined, true);
    }

    useEffect(() => {
        const keypressed = (ev: KeyboardEvent) => {
            KeysPressed[ev.key.toLocaleLowerCase()] = true;
            handleActions(ev);
        };

        document.addEventListener('keydown', keypressed); 

        return () => {
            document.removeEventListener('keydown', keypressed); 
        }
    }, deps)

    useEffect(() => {
        const keyreleased = (ev: KeyboardEvent) => {
            KeysPressed[ev.key.toLowerCase()] = false;

            if (ev.key == 'Meta') {
                // there is a weird bug where command key on mac being relased doesn't allow other keys to be released
                Object.keys(KeysPressed).forEach(key => { KeysPressed[key] = false; });
            }
        };

        document.addEventListener('keyup', keyreleased);

        return () => {
            document.removeEventListener('keyup', keyreleased);
        }
    }, [])
}